#choose {
    padding: 100px 0;

    h3 {
        margin-top: 50px; }

    .list {
        margin: 20px 0 0 100px;
        padding: 0;
        list-style: none;

        li {
            width: 50%;
            float: left;
            line-height: 30px;
            color: #000;
            font-weight: 600;

            i {
                margin-right: 10px; } } } }




.tab {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    background: #f5f5f5;
    font-weight: 300;
    color: #888;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    height: 450px; }


.tabs {
    display: table;
    position: relative;
    overflow: hidden;
    margin: 0;

    padding: 0;

    li {
        line-height: 38px;
        overflow: hidden;
        padding: 0;
        position: relative;
        display: inline-block;


        a {
            background-color: #eff0f2;
            border-bottom: 1px solid #fff;
            color: #888;
            font-weight: 500;
            display: block;
            letter-spacing: 0;
            outline: none;
            padding: 0 17px;
            text-decoration: none;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            border-bottom: 2px solid $color_theme; }

        &.current a {
            color: #fff;
            background: $color_theme; } } }



.tabs_item {
    display: none;
    padding: 20px;


    h4 {
        font-weight: bold;
        color: $color_theme;
        font-size: 20px; }


    img {
        width: 250px;
        float: left;
        margin-right: 30px; }


    &:first-child {
        display: block; } }

.tab_content {
    padding: 10px 0; }

.panel-group {
    margin-top: 20px; }

.panel-body {
    p {
        margin-bottom: 0; } }

.panel-default {
    text-align: left;
    >   .panel-heading {
        background-color: $color_theme;
        padding: 15px;
        color: #FFF;
        text-decoration: none;

        a {

            &:hover, &:focus {
                text-decoration: none; } } } }


.panel-heading {

    .accordion-toggle:after {
        font-family: "dt-icons";
        content: "\e041";
        float: right;
        color: #FFF;
        font-size: 20px; } }

.panel-heading {
    .accordion-toggle {
        display: block;

        &.collapsed {
            &:after {
                content: "\e048"; } } } }
