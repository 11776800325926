/*!
  Theme Name: GP
  Theme URI: http://www.gpthemes.co/products/gp/
  Author: Golden Plus Theme
  Author URI: http://www.gpthemes.co/
  Description: The Ultimate Financial / Business Template
  Version: 1.1.1
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: gp
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Loading
    ## Header
    ## Banner
    ## feature
    ## About
    ## template
    ## team
    ## Skills
    ## Countup
    ## Recent Work
    ## Follow
    ## Service
    ## Like
    ## Pricing
    ## Brand Slider
    ## Testimonial
    ## Testimonial
    ## Blog
    ## Contact
    ## Experience
    ## Portfolio
    ## Newsletter
    ## Blog Page
    ## Start
    ## Choose
    ## widgets
    ## My Exprience
    ## Fotter
    ## Responsive

  --------------------------------------------------------------*/
@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Regular-webfont.eot");
  src: url("../fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular-webfont.woff2") format("woff2"), url("../fonts/OpenSans-Regular-webfont.woff") format("woff"), url("../fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Regular-webfont.svg#OpenSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: OpenSans;
    src: url("../fonts/OpenSans-Regular-webfont.svg#OpenSans") format("svg");
  }
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Bold-webfont.eot");
  src: url("../fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold-webfont.woff2") format("woff2"), url("../fonts/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("../fonts/OpenSans-Bold-webfont.svg#OpenSans") format("svg");
  font-weight: bold;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: OpenSans;
    src: url("../fonts/OpenSans-Bold-webfont.svg#OpenSans") format("svg");
  }
}

body {
  color: #333;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  line-height: 26px;
}

h1, h2, h3, h4, h5 {
  font-family: "Roboto", sans-serif;
  margin-top: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.gp-btn {
  color: #FFF;
  padding: 12px 20px;
  text-transform: uppercase;
  background: #000;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  line-height: 14px;
}

.gp-btn:hover {
  background: #f45c27;
  color: #FFF;
}

.read-more {
  background: #000;
  padding: 12px 20px 12px 20px;
  font-size: 14px;
  color: #FFF;
  font-weight: 600;
  line-height: 14px;
  position: relative;
  display: inline-block;
}

.read-more i {
  font-size: 16px;
  position: absolute;
  right: 10px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  top: 28%;
}

.read-more:hover, .read-more:focus {
  background: #f45c27;
  color: #FFF;
  padding-right: 30px;
  text-decoration: none;
}

.read-more:hover i, .read-more:focus i {
  opacity: 1;
}

.border-none {
  border: none !important;
}

.starter-template {
  padding: 40px 15px;
  text-align: center;
}

.sprite {
  display: inline-block;
  width: 32px;
  margin: 10px;
}

.sprite:hover {
  opacity: 0.8;
}

.screenreader {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
}

svg {
  display: block;
}

a {
  transition: all 0.15s ease-in-out;
}

a,
a:hover {
  text-decoration: none;
}

.grid-half {
  width: 50%;
  float: left;
}

@media (max-width: 768px) {
  .grid-half {
    width: 100%;
  }
}

.margin-none {
  margin: 0;
}

.padding-none {
  padding: 0;
}

.section-title {
  margin-top: 0;
  margin-bottom: 20px;
  color: #000;
  font-weight: 700;
  font-size: 36px;
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
  letter-spacing: 0.05em;
}

.section-title:first-letter {
  color: #f45c27;
}

.section-title:after {
  position: absolute;
  content: '';
  width: 30%;
  height: 3px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: #f45c27;
}

.section-caption {
  font-size: 16px;
  color: #000;
  letter-spacing: 0.5px;
  position: relative;
  margin-bottom: 50px;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.Switcher__control {
  background-image: none;
}

.Switcher__control i {
  font-size: 20px;
  margin-top: 4px;
}

/*=========  Loader  ==========*/
/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #f45c27;
  width: 42px;
  height: 42px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  z-index: 1000;
}

.return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 11px;
  top: 9px;
  font-size: 19px;
  transition: all 0.3s ease;
}

.return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

.return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.back-top {
  display: block;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.dt-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 800;
  transition: all 0.3s ease-in-out;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-container {
  width: 90%;
  margin: 0 auto;
}

.site-logo {
  float: left;
}

.site-logo .logo-white {
  display: block;
}

.site-logo .logo-black {
  display: none;
}

nav {
  float: right;
}

nav ul {
  background: rgba(0, 0, 0, 0.9);
  padding: 20px 0;
}

nav .sf-menu {
  margin: 0;
}

nav .sf-menu li {
  background-color: transparent;
}

nav .sf-menu li a {
  border: none;
  color: #FFF;
  padding: 17px 14px;
  font-size: 13px;
  font-weight: 500;
}

nav .sf-menu li a i {
  margin-left: 10px;
}

nav .sf-menu li a.menu-option {
  padding: 17px 5px;
  font-size: 16px;
}

nav .sf-menu li a sup {
  font-weight: 700;
  color: #f45c27;
  font-size: 14px;
}

nav .sf-menu li a:hover {
  color: #f45c27;
}

nav .sf-menu li:hover, nav .sf-menu li.sfHover {
  background-color: transparent;
}

nav .sf-menu li ul {
  width: 200px;
  padding: 20px;
}

nav .sf-menu li ul li {
  background-color: transparent;
}

nav .sf-menu li ul li a {
  padding: 9px 0;
}

nav .sf-menu li ul li ul {
  border-left: 2px solid #f45c27;
  left: 112%;
  top: -20px;
}

nav .sf-menu li ul li ul li a {
  padding: 5px 0;
}

.sf-arrows .sf-with-ul {
  padding-right: 10px;
}

nav .sf-menu {
  background-color: transparent;
}

nav .sf-menu > li > a {
  text-transform: uppercase;
  color: #fff;
}

nav .sf-menu > li > ul {
  padding: 20px;
  top: 126%;
}

nav .sf-menu > li > ul li a i {
  position: absolute;
  right: 0;
  top: 14px;
}

.sf-menu > li:last-child > ul {
  left: auto;
  right: 0%;
}

.sf-menu > li:last-child > ul:before {
  left: auto;
  right: 10%;
}

nav .sf-menu {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

/*=========  Burgermenu  ==========*/
.nav-toggle {
  cursor: pointer;
}

.menu-toggle {
  position: relative;
  width: 30px;
  height: 24px;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-toggle > div {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  height: 2px;
  background-color: #FFF;
  transition: all 0.3s ease-in-out;
}

.transparent-header:not(.menu-style-2-open) .site-header .menu-toggle > div {
  background-color: #FFF;
}

.transparent-header.fix-header .site-header .menu-toggle > div {
  background-color: #2c2c2c;
}

.menu-toggle > div:nth-child(2) {
  top: 40%;
  margin-top: -2px;
}

.menu-toggle > div:nth-child(3) {
  top: 68%;
}

.cbp-spmenu-push-toright .menu-toggle.active > div:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(135deg);
  -ms-transform: translateY(10px) rotate(135deg);
  transform: translateY(10px) rotate(135deg);
}

.cbp-spmenu-push-toright .menu-toggle.active > div:nth-child(2) {
  opacity: 0;
}

.cbp-spmenu-push-toright .menu-toggle.active > div:nth-child(3) {
  -webkit-transform: translateY(-4px) rotate(-135deg);
  -ms-transform: translateY(-4px) rotate(-135deg);
  transform: translateY(-4px) rotate(-135deg);
  width: 100%;
  background-color: #FFF;
}

/*=========  Sticky Menu  ==========*/
.navbar-small {
  position: fixed;
  top: 0;
  background: #FFF;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
  padding: 5px 0;
  border-bottom: 0;
}

.navbar-small .site-logo .logo-white {
  display: none;
}

.navbar-small .site-logo .logo-black {
  display: block;
}

.navbar-small .site-logo .logo-black img {
  width: 100%;
}

.navbar-small nav > ul {
  background-color: transparent;
  opacity: 1;
}

.navbar-small nav > ul > li > a {
  color: #000;
}

.navbar-small nav > ul > li > ul {
  top: 110%;
}

.visible-menu .dt-mobile-header {
  left: 0;
}

.navbar-small {
  background: #fff;
}

.search-wrapper {
  position: absolute;
  right: 24%;
  width: 56%;
  background: #FFF;
  top: 0;
  height: 90px;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.search-wrapper input {
  width: 95%;
  padding: 10px 50px 10px 10px;
  height: 50px;
  font-size: 20px;
  margin: 20px auto;
  display: block;
  background-color: transparent;
  color: #FFF;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
}

.search-wrapper .search-btn {
  position: absolute;
  top: 32px;
  right: 40px;
  color: #FFF;
  font-size: 20px;
}

.search-wrapper.search-open {
  opacity: 1;
  cursor: pointer;
  z-index: 1;
}

.navbar-small .search-wrapper {
  background: #FFF;
  height: 70px;
}

.navbar-small .search-wrapper input {
  color: #000;
  border-color: #000;
  margin: 10px auto;
}

.navbar-small .search-wrapper .search-btn {
  color: #000;
  top: 22px;
}

/*=========  Mobile Menu  ==========*/
.mobile-header {
  display: none;
}

.mobile-menu {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
}

#accordian {
  background: #191919;
  color: white;
  height: 100%;
}

#accordian ul {
  margin: 0;
  padding: 0;
}

#accordian h3 {
  background: #000;
  margin-bottom: 0;
  border-bottom: 1px solid #222;
}

#accordian h3 a {
  padding: 0 10px;
  font-size: 12px;
  line-height: 34px;
  display: block;
  color: white;
  text-decoration: none;
}

#accordian h3 a i {
  margin-right: 10px;
}

#accordian h3:hover {
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
}

#accordian li {
  list-style-type: none;
}

#accordian ul ul li a,
#accordian h4 {
  color: white;
  text-decoration: none;
  font-size: 11px;
  line-height: 27px;
  display: block;
  padding: 0 15px;
  transition: all 0.15s;
  position: relative;
}

#accordian ul ul li a:hover {
  background: #464646;
  border-left: 5px solid #FFF;
}

#accordian ul ul {
  display: none;
}

#accordian li.active > ul {
  display: block;
}

#accordian ul ul ul {
  margin-left: 15px;
  border-left: 1px dotted rgba(0, 0, 0, 0.5);
}

#accordian a:not(:only-child):after {
  content: "\f104";
  font-family: fontawesome;
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 14px;
}

#accordian .active > a:not(:only-child):after {
  content: "\f107";
}

/*=========  JPush menu  ==========*/
/* Orientation-dependent styles for the content of the menu */
.cbp-spmenu-left {
  left: -250px;
}

.cbp-spmenu-right {
  right: -250px;
}

.cbp-spmenu-left.cbp-spmenu-open {
  left: 0px;
  z-index: 99999;
}

.cbp-spmenu-right.cbp-spmenu-open {
  right: 0px;
}

/* Push classes applied to the body */
.cbp-spmenu-push {
  overflow-x: hidden;
  position: relative;
  left: 0;
}

.cbp-spmenu-push-toright {
  left: 250px;
}

.cbp-spmenu-push-toleft {
  left: -250px;
}

/* Transitions */
.cbp-spmenu,
.cbp-spmenu-push {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*=========  Side Contact  ==========*/
.side-contact {
  width: 250px;
  background: #232323;
  position: fixed;
  top: 0;
  height: 100vh;
  padding: 20px 30px;
  z-index: 9999;
  overflow-y: auto;
}

.quick-contact {
  margin-top: 100px;
}

.quick-contact h3 {
  color: #FFF;
  margin-bottom: 20px;
}

.quick-contact form input[type="text"], .quick-contact form textarea {
  width: 100%;
  padding: 10px;
  background: transparent;
  color: #FFF;
  outline: none;
  border-radius: 4px;
  border: 1px solid #f45c27;
}

.quick-contact form input[type="text"] {
  margin-bottom: 15px;
}

.quick-contact form textarea {
  height: 150px;
}

.cart-list {
  width: 250px !important;
}

/*========= Page Loader  ==========*/
#pre-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #FFF;
  top: 0;
  left: 0;
  z-index: 99999;
}

.cssload-box-loading {
  width: 49px;
  height: 49px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cssload-box-loading:before {
  content: '';
  width: 49px;
  height: 5px;
  background: black;
  opacity: 0.1;
  position: absolute;
  top: 58px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.58s linear infinite;
  animation--o-animation: shadow 0.58s linear infinite;
  animation--ms-animation: shadow 0.58s linear infinite;
  animation--webkit-animation: shadow 0.58s linear infinite;
  animation--moz-animation: shadow 0.58s linear infinite;
}

.cssload-box-loading:after {
  content: '';
  width: 49px;
  height: 49px;
  background: #f45c27;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  animation: cssload-animate 0.58s linear infinite;
  animation--o-animation: cssload-animate 0.58s linear infinite;
  animation--ms-animation: cssload-animate 0.58s linear infinite;
  animation--webkit-animation: cssload-animate 0.58s linear infinite;
  animation--moz-animation: cssload-animate 0.58s linear infinite;
}

@keyframes cssload-animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 39px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@-o-keyframes cssload-animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -o-transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -o-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 39px;
  }
  75% {
    -o-transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -o-transform: translateY(0) rotate(90deg);
  }
}

@-ms-keyframes cssload-animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -ms-transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -ms-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 39px;
  }
  75% {
    -ms-transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -ms-transform: translateY(0) rotate(90deg);
  }
}

@-webkit-keyframes cssload-animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -webkit-transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 39px;
  }
  75% {
    -webkit-transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
  }
}

@-moz-keyframes cssload-animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -moz-transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -moz-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 39px;
  }
  75% {
    -moz-transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -moz-transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}

@-o-keyframes shadow {
  0%,
  100% {
    -o-transform: scale(1, 1);
  }
  50% {
    -o-transform: scale(1.2, 1);
  }
}

@-ms-keyframes shadow {
  0%,
  100% {
    -ms-transform: scale(1, 1);
  }
  50% {
    -ms-transform: scale(1.2, 1);
  }
}

@-webkit-keyframes shadow {
  0%,
  100% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1);
  }
}

@-moz-keyframes shadow {
  0%,
  100% {
    -moz-transform: scale(1, 1);
  }
  50% {
    -moz-transform: scale(1.2, 1);
  }
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
/*=========  Revolution Slideshow  ==========*/
.tp-caption.main-slide-title, .main-slide-title {
  font-weight: 900;
  color: #FFF;
  font-family: "Raleway", sans-serif;
}

.tp-caption.main-slide-sub, .main-slide-sub {
  color: #FFF;
}

.tp-caption.description, .description {
  color: #FFF;
  font-weight: 600;
}

.tp-caption.rev-two-sub-title, .rev-two-sub-title {
  color: #FFF;
}

.tp-caption.rev-two-subtitle, .rev-two-subtitle {
  color: #FFF;
  text-transform: uppercase;
}

.tp-caption.rv-two-title, .rv-two-title {
  color: #FFF;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
}

.tp-caption .rev-three-title, .rev-three-title {
  font-weight: 700;
  color: #FFF;
  font-family: "Raleway", sans-serif;
}

.tp-caption.gp-btn:hover, .gp-btn:hover {
  background: #000;
  color: #FFF;
}

.tp-caption.name, .name {
  color: #f45c27;
}

/*========= Banner Static Image  ==========*/
.tp-caption.name, .name {
  color: #000;
}

.tp-caption.gp-btn .gp-btn:hover {
  background: #000;
  color: #FFF;
}

/*========= Banner Particles  ==========*/
.banner-particles {
  height: 100vh;
  background-size: cover;
  position: relative;
}

.banner-particles .banner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
}

.banner-particles .banner-content h3 {
  color: #FFF;
  font-weight: 600;
  font-size: 20px;
}

.banner-particles .banner-content h1 {
  color: #f45c27;
  font-weight: 900;
  font-size: 70px;
}

.banner-particles .banner-content p {
  color: #FFF;
  font-size: 18px;
  margin-bottom: 20px;
}

.banner-particles .banner-content .gp-btn {
  background: #f45c27;
  padding: 15px 30px;
  text-transform: capitalize;
}

.banner-particles .banner-content .gp-btn:hover, .banner-particles .banner-content .gp-btn:focus {
  background: #FFF;
  color: #000;
  text-decoration: none;
}

/*--------------------------------------------------------------
  ##  Feature
  --------------------------------------------------------------*/
#feature {
  padding: 100px 0;
  background: #f5f5f5;
  overflow: hidden;
}

.feature .feature-item {
  padding: 30px 15px;
  backface-visibility: hidden;
  cursor: pointer;
  background: #FFF;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.25s cubic-bezier(0.53, 0.01, 0.18, 1);
}

.feature .feature-item .ab-icon {
  height: 80px;
  width: 80px;
  margin: 0 auto;
  text-align: center;
  line-height: 95px;
  margin-bottom: 20px;
  border-radius: 50%;
  position: relative;
  color: #000;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  float: left;
  z-index: 1;
}

.feature .feature-item .ab-icon i {
  font-size: 55px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

.feature .feature-item .ab-icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: all 0.3s ease-in-out;
}

.feature .feature-item .feature-content {
  margin-left: 100px;
}

.feature .feature-item .feature-content h3 {
  position: relative;
  font-size: 22px;
  padding-bottom: 10px;
  display: inline-block;
}

.feature .feature-item .feature-content h3:after {
  content: '';
  position: absolute;
  width: 30%;
  background: #000;
  bottom: 0;
  left: 0;
  height: 2px;
}

.feature .feature-item .feature-content .gp-btn {
  padding: 5px 20px;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}

.feature .feature-item .feature-content .gp-btn i {
  display: none;
  color: #FFF;
  margin-left: 5px;
}

.feature .feature-item .feature-content .gp-btn:hover i {
  display: inline-block;
}

.feature .feature-item:hover {
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px);
}

.feature .feature-item:hover .ab-icon i {
  font-size: 30px;
  color: #FFF;
}

.feature .feature-item:hover .ab-icon:after {
  background: #000;
}

#feature.feature-two {
  text-align: center;
}

#feature.feature-two .section-caption {
  margin-bottom: 100px;
}

#feature.feature-two .feature-item {
  padding: 70px 20px 20px;
  position: relative;
}

#feature.feature-two .feature-item .ab-icon {
  float: none;
  top: -45px;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
  position: absolute;
  background: #e4e4e4;
  line-height: 133px;
  line-height: 80px;
  line-height: 105px;
  backface-visibility: hidden;
}

#feature.feature-two .feature-item .ab-icon i {
  font-size: 40px;
}

#feature.feature-two .feature-item .feature-content {
  margin-left: 0;
}

#feature.feature-two .feature-item .feature-content h3:after {
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
}

#feature.feature-two .feature-item:hover .ab-icon {
  height: 80px;
  width: 80px;
  line-height: 95px;
}

#feature.feature-two .feature-item:hover .ab-icon i {
  font-size: 30px;
}

#feature.feature-two.feature-three {
  background: #f45c27;
}

#feature.feature-two.feature-three .section-title {
  color: #FFF;
}

#feature.feature-two.feature-three .section-title:first-letter {
  color: #000;
}

#feature.feature-two.feature-three .section-title:after {
  background: #FFF;
}

#feature.feature-four {
  padding: 20px 0;
  background-color: transparent;
}

#feature.feature-four .feature-item {
  padding: 20px 0;
  border-right: 1px solid #ededed;
}

#feature.feature-four .feature-item .ft-icon {
  float: left;
  height: 60px;
  width: 60px;
  line-height: 70px;
  background: #f45c27;
  text-align: center;
  border-radius: 5px;
}

#feature.feature-four .feature-item .ft-icon i {
  font-size: 30px;
  color: #FFF;
}

#feature.feature-four .feature-item .content {
  margin-left: 70px;
}

#feature.feature-four .feature-item .content h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

#feature.feature-four .feature-item .content h3:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background: #000;
}

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
#about {
  padding: 100px 0;
  background-size: cover;
  overflow: hidden;
}

#about .about-thumb img {
  width: 100%;
}

.about-details .section-title {
  color: #FFF;
}

.about-details .section-title:after {
  left: 0;
  transform: translateX(0);
}

.about-details p {
  color: #FFF;
  font-size: 16px;
}

.about-details .gp-btn {
  margin-top: 30px;
  background: #FFF;
  color: #000;
}

.about-details .gp-btn:hover {
  background: #f45c27;
  color: #FFF;
}

/*=========  About Two  ==========*/
#about.about-two {
  background: #efefef;
  padding: 100px 0;
}

#about.about-two .about-details .our-history .section-title {
  color: #000;
  margin-top: 40px;
}

#about.about-two .about-details .our-history p {
  color: #000;
  font-size: 15px;
  margin-bottom: 20px;
}

#about.about-two .aboute-thumb {
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
}

#about.about-two .aboute-thumb img {
  width: 100%;
}

/*=========  About Three  ==========*/
.youtube-banner {
  height: 100%;
  overflow: hidden;
}

.youtube-wrapper {
  position: relative;
  padding-bottom: 56.20%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: #191a1c;
  cursor: pointer;
}

.youtube-wrapper:before {
  content: "\f04b";
  font-family: 'FontAwesome';
  font-size: 34px;
  margin-top: 7px;
  margin-left: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: transform .2s, opacity .2s;
  -moz-transition: transform .2s, opacity .2s;
  -webkit-transition: transform .2s, opacity .2s;
}

.youtube-wrapper:after {
  content: '';
  position: absolute;
  margin-top: 6px;
  height: 90px;
  width: 90px;
  border: none;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  top: 50%;
  left: 50%;
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: background-color .2s, transform .2s, opacity 1.2s;
  -moz-transition: background-color .2s, transform .2s, opacity .2s;
  -webkit-transition: background-color .2s, transform .2s, opacity 1.2s;
}

.youtube-wrapper.reveal:after, .youtube-wrapper.reveal:before {
  opacity: 0;
  visibility: hidden;
  transition: visibility .4s, opacity .4s;
}

.youtube-wrapper.reveal iframe {
  visibility: visible;
}

.youtube-wrapper iframe, .youtube-wrapper object, .youtube-wrapper embed {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  visibility: hidden;
}

.youtube-wrapper .youtube-poster {
  height: 315px;
  background-position: center;
  background-size: cover;
}

/*=========  About me  ==========*/
#about.about-me {
  background: #eee;
}

#about.about-me .section-title {
  margin-bottom: 50px;
}

.ab-thumb {
  padding: 10px;
  border: 3px solid #000;
}

.ab-thumb img {
  width: 100%;
}

.about-me {
  text-align: left;
}

.about-me h3 {
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 30px;
  position: relative;
}

.about-me h3:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 50px;
  height: 3px;
  background: #f45c27;
}

.about-me p {
  font-weight: 500;
  margin: 20px 0 30px;
}

.about-me .gp-btn {
  margin-right: 15px;
}

.table tbody tr {
  border-bottom: 1px solid #ededed;
}

.table tbody tr td {
  border-color: #999;
  font-weight: 600;
  padding: 12px 0;
  font-size: 15px;
}

.table tbody tr td span {
  color: #f45c27;
  font-weight: 700;
  font-size: 16px;
}

/*--------------------------------------------------------------
  ##  Template
  --------------------------------------------------------------*/
#template {
  text-align: center;
  color: #FFF;
  padding: 100px 0;
  background-size: cover;
}

.template-content h2 {
  text-transform: uppercase;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.template-content p {
  font-size: 16px;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
.team {
  position: relative;
  padding: 50px 0 80px;
  background-size: cover;
  background-repeat: no-repeat;
}

.team .team-two-caption {
  margin-bottom: 50px;
  text-align: left;
}

.team .team-two-caption p {
  color: #000;
  line-height: 26px;
}

.team .gallery-top,
.team .gallery-thumbs {
  overflow: hidden;
}

.team .gallery-top .swiper-slide img {
  float: left;
}

.team .gallery-top .swiper-slide .slider-content {
  margin-left: 310px;
  max-width: 550px;
}

.team .gallery-top .swiper-slide .slider-content h4 {
  font-size: 24px;
  font-weight: 700;
  color: #f45c27;
}

.team .gallery-top .swiper-slide .slider-content span {
  color: #444;
  display: block;
  margin-bottom: 30px;
}

.team .gallery-top .swiper-slide .slider-content p {
  color: #000;
  line-height: 26px;
  margin-bottom: 30px;
}

.team .gallery-top .banner-next,
.team .gallery-top .banner-prev {
  right: 21.5%;
  height: 40px;
  width: 40px;
  border: 1px solid #000;
  position: absolute;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.team .gallery-top .banner-next i,
.team .gallery-top .banner-prev i {
  font-size: 35px;
}

.team .gallery-top .banner-next:hover,
.team .gallery-top .banner-prev:hover {
  background: #f45c27;
  border-color: transparent;
}

.team .gallery-top .banner-next:hover i,
.team .gallery-top .banner-prev:hover i {
  color: #FFF;
}

.team .gallery-top .banner-next {
  top: auto;
}

.team .gallery-top .banner-prev {
  top: 20%;
  left: auto;
}

.team .gallery-thumbs {
  width: 100px;
  height: 330px;
  position: absolute;
  right: 20%;
  top: 30%;
}

.team .gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.3;
  cursor: pointer;
}

.team .gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

.gp-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gp-social-link li {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 36px;
  background: #444;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.gp-social-link li a {
  color: #FFF;
  text-decoration: none;
  display: block;
}

.gp-social-link li:hover {
  background: #f45c27;
}

/*=========  Team two  ==========*/
.team-two {
  padding: 100px 0;
  text-align: center;
}

.member {
  position: relative;
  cursor: pointer;
}

.member img {
  width: 100%;
}

.member .team-overlay {
  position: absolute;
  width: 92.5%;
  height: 95%;
  left: 10px;
  top: 10px;
  padding: 10px;
  background: rgba(244, 92, 39, 0.8);
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.member .team-overlay .name {
  margin-top: 150px;
}

.member .team-overlay .name h3 {
  color: #FFF;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0.05em;
  transform: translateX(30px);
  transition: all 0.3s;
}

.member .team-overlay .name p {
  color: #000;
  font-weight: 700;
  font-size: 16px;
  transform: translateX(35px);
  transition: all 0.5s;
}

.member .team-overlay p {
  transform: translateX(35px);
  transition: all 0.7s ease-in-out;
  color: #FFF;
}

.member .team-overlay .team-link {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

.member .team-overlay .team-link li {
  display: inline-block;
  height: 35px;
  width: 35px;
  background: rgba(0, 0, 0, 0.5);
  line-height: 38px;
  transtion: all 0.3s ease-in-out;
  transform: translateY(25px);
}

.member .team-overlay .team-link li a {
  font-size: 16px;
  color: #FFF;
  display: block;
  transtion: all 0.3s ease-in-out;
}

.member .team-overlay .team-link li:nth-child(1) {
  transition: all 0.3s;
}

.member .team-overlay .team-link li:nth-child(2) {
  transition: all 0.5s;
}

.member .team-overlay .team-link li:nth-child(3) {
  transition: all 0.7s;
}

.member .team-overlay .team-link li:nth-child(4) {
  transition: all 0.9s;
}

.member .team-overlay .team-link li:nth-child(5) {
  transition: all 1.1s;
}

.member:hover .team-overlay {
  opacity: 1;
}

.member:hover .team-overlay .name h3, .member:hover .team-overlay .name p {
  transform: translateX(0);
}

.member:hover .team-overlay p {
  transform: translateX(0);
}

.member:hover .team-overlay .team-link li {
  transform: translateY(0);
}

.member:hover .team-overlay .team-link li:hover {
  background: rgba(255, 255, 255, 0.5);
}

.member:hover .team-overlay .team-link li:hover a {
  color: #000;
}

/*=========  Team Three  ==========*/
.team-three {
  padding: 100px 0;
  text-align: center;
}

.team-container {
  overflow: hidden;
  position: relative;
}

.team-container .swiper-slide {
  position: relative;
}

.team-container .swiper-slide .member {
  text-align: left;
  overflow: hidden;
}

.team-container .swiper-slide .member img {
  width: 100%;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.team-container .swiper-slide .member .team-overlay {
  padding: 20px;
}

.team-container .swiper-slide .member .team-overlay .name {
  margin-top: 50px;
}

.team-container .swiper-slide .member .team-overlay .team-link {
  width: 83%;
  bottom: 70px;
}

.team-container .swiper-slide .member .team-overlay .team-link li {
  text-align: center;
}

.team-container .swiper-slide .member:hover img {
  transform: scale(1.1);
}

.team-container .slider-control {
  opacity: 0;
  transtion: all 0.3s ease-in-out;
}

.team-container .slider-control .tm-next-btn, .team-container .slider-control .tm-prev-btn {
  position: absolute;
  height: 40px;
  width: 40px;
  border: 1px solid #000;
  line-height: 47px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.team-container .slider-control .tm-next-btn i, .team-container .slider-control .tm-prev-btn i {
  font-size: 30px;
  color: #000;
}

.team-container .slider-control .tm-next-btn:hover, .team-container .slider-control .tm-prev-btn:hover {
  background: #f45c27;
  border-color: transparent;
}

.team-container .slider-control .tm-next-btn {
  right: 0;
}

.team-container:hover .slider-control {
  opacity: 1;
}

/*--------------------------------------------------------------
  ##  Skills
  --------------------------------------------------------------*/
#skills {
  background: #f8f8f8;
  overflow: hidden;
}

#skills .skills-thumb img {
  width: 100%;
}

#skills .skills {
  padding: 40px 0 0;
  text-align: center;
}

#skills .skills p {
  margin-bottom: 50px;
  font-size: 16px;
}

.skills-thumb {
  background-size: cover;
  height: 500px;
}

.pie-title-center {
  display: inline-block;
  position: relative;
  text-align: center;
  margin: 0 15px;
}

.pie-value {
  display: block;
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  height: 40px;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -20px;
  line-height: 40px;
}

.skill-grid {
  width: 25%;
  float: left;
}

/*=========  Skills Two  ==========*/
#skills.skills-two {
  padding: 100px 0;
  background-size: cover;
  background: transparent;
}

#skills.skills-two .skills-title {
  font-weight: 700;
  text-transform: uppercase;
}

#skills.skills-two h4 {
  font-size: 18px;
  margin-bottom: 40px;
}

.client .clients-items .item {
  float: left;
  width: 29%;
  margin: 10px;
  background: #f45c27;
}

.client .clients-items .item img {
  width: 100%;
}

.skills-wrapper {
  max-width: 550px;
  margin: 0 auto;
  top: 50%;
}

.skills-heading span {
  font-size: 16px;
  font-weight: 700;
  color: #111111;
}

.skills-heading p {
  color: #000;
  float: right;
  margin-bottom: 0;
}

progress {
  border: 0;
  height: 15px;
  width: 100%;
  margin-bottom: 10px;
}

progress::-webkit-progress-bar {
  background-color: #eaeaea;
}

progress::-webkit-progress-value {
  background-color: #f45c27;
  height: 15px;
}

.skill-item {
  float: left;
}

/*=========  Skills Four  ==========*/
#skills.skills-four {
  padding-top: 100px;
}

#skills.skills-four .skills-wrapper {
  margin-top: 30px;
}

#skills.skills-four .section-title:after {
  transform: translateX(0);
  left: 0;
}

#skills.skills-four h4 {
  margin-bottom: 20px;
}

#skills.skills-four > p {
  margin-bottom: 20px;
}

#skills.skills-four .skills-thumb {
  max-width: 350px;
  width: 100%;
  height: auto;
}

#skills.skills-four #skills-inner {
  margin-top: 50px;
}

.ProgressBar,
.ProgressBar-contentCircle {
  display: table;
  height: 130px;
  position: relative;
  width: 130px;
  margin: 0 auto;
}

.ProgressBar-circle,
.ProgressBar-background {
  fill: none;
  stroke: #f45c27;
  stroke-width: 8;
  stroke-linecap: round;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  position: relative;
  z-index: 10;
}

.ProgressBar-background {
  stroke: #ccc;
  stroke-width: 7;
  z-index: 0;
}

.ProgressBar-percentage {
  color: #000;
  font-size: 30px;
  text-align: center;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  position: absolute;
  top: 50px;
}

/************************/
/* structure de la page */
/************************/
.Content {
  height: 270px;
  margin: -135px 0 0 0;
  position: absolute;
  top: 50%;
  width: 100%;
}

.List {
  display: flex;
  list-style: none;
  margin: 10px auto;
  padding: 0px;
  width: 520px;
  height: 130px;
}

.List-item {
  width: 150px;
  display: inline-block;
  margin-bottom: 40px;
}

.Title {
  text-align: center;
}

.circle-wrapper {
  width: 70%;
  min-height: 150px;
  height: 100%;
  margin: 0 auto;
}

.circle-item {
  position: relative;
}

.circle-item h3 {
  position: absolute;
  top: 140px;
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
.countup {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  overflow: hidden;
  background-size: cover;
  text-align: center;
}

.countup .section-title {
  color: #FFF;
  margin-bottom: 60px;
}

.countup .section-title:after {
  left: 50%;
  transform: translateX(-50%);
}

.counter-box {
  border: 3px solid #f45c27;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.counter-box .count i {
  font-size: 50px;
  color: #f45c27;
  margin-bottom: 25px;
}

.counter-box .count h2 {
  font-size: 50px;
  color: #FFF;
  font-weight: 700;
}

.counter-box .count p {
  color: #FFF;
  font-size: 16px;
  font-weight: 300;
}

/*--------------------------------------------------------------
  ##  Recent Work
  --------------------------------------------------------------*/
#portfolio {
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: #f8f8f8;
}

#portfolio p {
  margin-bottom: 30px;
  font-size: 16px;
}

.portfolio-filter {
  margin: 0;
  padding-left: 15px;
  list-style: none;
  margin-bottom: 50px;
}

.portfolio-filter li {
  display: inline-block;
  margin-right: 10px;
}

.portfolio-filter li a {
  padding: 12px 22px;
  border: 1px solid #ababab;
  color: #ababab;
  font-size: inherit;
  line-height: 10px;
  color: #000;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.portfolio-filter li a:hover, .portfolio-filter li a:focus {
  color: #FFF;
  background: #f45c27;
  border-color: transparent;
  text-decoration: none;
}

.portfolio-filter li:first-child a {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.portfolio-filter li:last-child a {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.gp-portfolio-gallery {
  margin-bottom: 40px;
}

.gp-portfolio-item {
  width: 33%;
  padding: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.gp-portfolio-item img {
  width: 100%;
}

.gp-portfolio-item.width-hulp {
  width: 66%;
}

.inner {
  position: relative;
  overflow: hidden;
}

.inner img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.inner .portfolio-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(244, 92, 39, 0.8);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
}

.inner .portfolio-overlay h3 {
  position: absolute;
  left: -70px;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
  margin: 0;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.inner .portfolio-overlay .option {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inner .portfolio-overlay .option a {
  text-align: center;
  height: 40px;
  width: 40px;
  background: #ededed;
  color: #000;
  line-height: 48px;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  margin-right: 5px;
}

.inner .portfolio-overlay .option a:hover {
  background: #000;
  color: #fff;
  text-decoration: none;
}

.inner .portfolio-overlay .option a:first-child {
  transform: translateY(-20px);
}

.inner .portfolio-overlay .option a:last-child {
  transform: translateY(20px);
}

.inner:hover img {
  transform: scale(1.1);
}

.inner:hover .portfolio-overlay {
  opacity: 1;
  transform: scale(1);
}

.inner:hover .portfolio-overlay:before {
  height: 40px;
}

.inner:hover .portfolio-overlay:after {
  width: 40px;
}

.inner:hover .portfolio-overlay h3 {
  left: 0;
}

.inner:hover .portfolio-overlay a:first-child {
  transform: translateY(0);
}

.inner:hover .portfolio-overlay a:last-child {
  transform: translateY(0);
}

/*--------------------------------------------------------------
  ##  follow
  --------------------------------------------------------------*/
#follow {
  padding: 100px 0;
  background-size: cover;
  overflow: hidden;
}

#follow .section-title {
  color: #FFF;
}

#follow p {
  color: #FFF;
  font-size: 16px;
  margin-bottom: 50px;
}

.connected-icons {
  margin: 0;
  padding: 0;
  list-style: none;
}

.connected-icons li {
  display: inline-block;
  width: 16%;
}

.connected-icons li a {
  transition: all 0.3s ease-in-out;
}

.connected-icons li a span {
  height: 80px;
  width: 80px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  font-size: 40px;
  line-height: 80px;
  margin-bottom: 15px;
  color: #000;
  transition: all 0.3s ease-in-out;
  position: relative;
  backface-visibility: hidden;
}

.connected-icons li a span:after {
  border: 2px solid #f45c27;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -15px;
  transition: all 0.5s ease-in-out;
  content: '';
  opacity: 0;
  position: absolute;
  border-radius: 50%;
}

.connected-icons li a h4 {
  color: #f45c27;
  letter-spacing: 0.05em;
  font-size: 20px;
}

.connected-icons li a:hover span {
  background: rgba(244, 92, 39, 0.8);
  color: #FFF;
  font-size: 30px !important;
}

.connected-icons li a:hover span:after {
  opacity: 1;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
}

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
#service {
  padding: 100px 0 0;
  text-align: center;
  background: #FFF;
}

#service p {
  margin-bottom: 80px;
}

.service-items {
  margin-top: 50px;
}

.service-item {
  text-align: left;
  margin-bottom: 40px;
  cursor: pointer;
}

.service-item span {
  float: left;
  height: 80px;
  width: 80px;
  margin: 0 auto;
  text-align: center;
  line-height: 90px;
  position: relative;
  box-shadow: 3px #f45c27;
  color: #FFF;
  box-shadow: 0 0 0 3px #000;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 40px;
  border-radius: 50%;
  z-index: 10;
}

.service-item span:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #000;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  border-radius: 50%;
}

.service-item.item-right {
  text-align: right;
}

.service-item.item-right span {
  float: right;
}

.service-item.item-right .service-content {
  margin-left: 0;
  margin-right: 100px;
}

.service-item .service-content {
  margin-left: 100px;
}

.service-item .service-content h3 {
  color: #000;
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}

.service-item:hover span {
  box-shadow: 0 0 0 3px #f45c27;
}

.service-item:hover span:after {
  transform: scale(0.85);
  background: #f45c27;
}

.service-item:hover .service-content h3 {
  color: #f45c27;
}

.service-thumb {
  max-width: 450px;
}

.service-thumb img {
  width: 100%;
}

/*=========  Service Two  ==========*/
.ser-items-two .service-item {
  background: #f5f5f5;
  padding: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.ser-items-two .service-item span {
  border-radius: 4px;
}

.ser-items-two .service-item span:after {
  border-radius: 4px;
}

.ser-items-two .service-item .service-content p {
  margin: 0 !important;
  line-height: 24px;
}

/*=========  Service Three  ==========*/
#service.service-three {
  background: #f5f5f5;
  padding: 70px 0;
  overflow: hidden;
}

#service.service-three .section-title {
  margin-bottom: 50px;
}

.ser-items-three .service-item {
  padding: 40px 20px 20px 20px;
  text-align: center;
  background: #FFF;
  box-shadow: 0 10px 30px rgba(50, 50, 50, 0.1);
}

.ser-items-three .service-item i {
  color: #000;
  font-size: 60px;
  margin-bottom: 20px;
  display: block;
  transition: all 0.3s ease-in-out;
}

.ser-items-three .service-item h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding-bottom: 10px;
  position: relative;
  transition: all 0.4s ease-in-out;
  margin-bottom: 0;
}

.ser-items-three .service-item h3:after {
  position: absolute;
  width: 0;
  left: 50%;
  bottom: 0;
  height: 3px;
  content: '';
  background: #f45c27;
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
}

.ser-items-three .service-item p {
  margin-bottom: 0 !important;
}

.ser-items-three .service-item:hover i {
  transform: translateY(-20px);
  color: #f45c27;
}

.ser-items-three .service-item:hover h3 {
  transform: translateY(-20px);
  color: #f45c27;
}

.ser-items-three .service-item:hover h3:after {
  width: 70px;
}

/*--------------------------------------------------------------
  ##  like
  --------------------------------------------------------------*/
#like {
  padding: 100px 0;
  background-size: cover;
  text-align: center;
  position: relative;
}

#like h2 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
  color: #FFF;
}

#like p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #FFF;
  letter-spacing: 0.05em;
}

#like .gp-btn {
  background: #FFF;
  color: #000;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

#like .gp-btn:hover {
  background: #f45c27;
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0;
  text-align: center;
  background: #f5f5f5;
}

#pricing > p {
  font-size: 16px;
  margin-bottom: 50px;
}

.single-pricing {
  margin: 0 auto;
  max-width: 430px;
  background: #fff;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
}

.single-pricing .pricing-head {
  padding: 15px 0;
  background: #f45c27;
}

.single-pricing .pricing-head h4 {
  color: #000;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 800;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.single-pricing .pricing-head h4:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  background: #000;
  height: 3px;
  width: 50%;
  transform: translateX(-50%);
}

.single-pricing .pricing-head h2 {
  font-size: 52px;
  font-weight: 900;
  color: #FFF;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 40px;
}

.single-pricing .pricing-data {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single-pricing .pricing-data li {
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
}

.single-pricing .pricing-data li:nth-child(odd) {
  background: #f8f8f8;
}

.single-pricing .pricing-btn {
  background: #f45c27;
  padding: 20px 0;
}

.single-pricing .pricing-btn .gp-btn {
  border: none;
  transition: all 0.3s ease-in-out;
}

.single-pricing .pricing-btn .gp-btn:hover {
  background: #FFF;
  color: #000;
}

/*=========  Pricing Two  ==========*/
#pricing.pricing-two {
  background-size: cover;
  background-attachment: fixed;
}

#pricing.pricing-two .section-title {
  color: #FFF;
}

#pricing.pricing-two p {
  color: #FFF;
}

#pricing.pricing-two .single-pricing {
  background: #fff;
  padding: 5px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  max-width: 400px;
}

#pricing.pricing-two .single-pricing .pricing-head {
  background: #000;
  padding-top: 0;
}

#pricing.pricing-two .single-pricing .pricing-head h4 {
  display: block;
  background: #f45c27;
  margin: 0;
  padding: 20px 0;
  color: #FFF;
}

#pricing.pricing-two .single-pricing .pricing-head h4:after {
  display: none;
}

#pricing.pricing-two .single-pricing .pricing-head h2 {
  margin-top: 25px;
}

#pricing.pricing-two .single-pricing .pricing-head p {
  color: #f45c27;
  margin-bottom: 0;
}

#pricing.pricing-two .single-pricing .pricing-btn .gp-btn {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
}

#pricing.pricing-two .single-pricing .pricing-btn .gp-btn:hover {
  background: #FFF !important;
}

#pricing.pricing-two .single-pricing:hover {
  transform: scale(1.04);
}

/*--------------------------------------------------------------
  ##  Brand
  --------------------------------------------------------------*/
#brand {
  padding: 80px 0;
  text-align: center;
}

#brand .brand-container {
  overflow: hidden;
}

#brand .brand-container .swiper-slide {
  background: #f5f5f5;
}

.brand-two {
  background: #f45c27;
}

.brand-two h2 {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.brand-two .client-thumb {
  background: #fff;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
#testimonial {
  padding: 100px 0;
  text-align: center;
  background-size: cover;
  background: #f5f5f5;
  position: relative;
  background-size: cover;
  background-position: center;
}

#testimonial .section-title {
  color: #FFF;
}

#testimonial p {
  color: #FFF;
}

#testimonial .testi-wrapper {
  width: 45%;
  margin: 0 auto;
  position: relative;
}

.testimonial-slider {
  margin-top: 50px;
}

.testimonial .content {
  float: left;
}

.testimonial .content h3 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #f45c27;
  margin-top: 0;
  margin-bottom: 10px;
}

.testimonial .content span {
  color: #999;
  font-size: 16px;
  font-weight: 700;
}

.testimonial .content .star {
  margin: 10px 0 20px;
  padding: 0;
}

.testimonial .content .star li {
  display: inline-block;
  margin: 0 5px;
}

.testimonial .content .star li i {
  color: #f45c27;
  font-size: 18px;
}

.testimonial .content p {
  font-size: 15px;
  letter-spacing: 1px;
}

.testimonial .content p i {
  color: #f45c27;
  font-size: 20px;
}

.testimonial .testi-thumb {
  float: right;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #f45c27;
}

.tm-control {
  width: 100px;
  position: absolute;
  right: 25px;
  bottom: -60px;
}

.tm-control .tm-prev, .tm-control .tm-next {
  height: 40px;
  width: 40px;
  border: 1px solid #FFF;
  line-height: 55px;
  cursor: pointer;
}

.tm-control .tm-prev i, .tm-control .tm-next i {
  font-size: 30px;
  color: #FFF;
}

.tm-control .tm-prev {
  float: left;
}

.tm-control .tm-next {
  float: right;
}

/*=========  Testimonial Two  ==========*/
#testimonial.testimonial-two .testi-wrapper {
  width: 100%;
  position: relative;
}

#testimonial.testimonial-two .testimonial .content {
  float: none;
}

#testimonial.testimonial-two .testimonial .testi-thumb {
  float: none;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
}

#testimonial.testimonial-two .testimonial .testi-thumb img {
  width: 100%;
}

#testimonial.testimonial-two .tm-control {
  bottom: 40px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
#blog {
  padding: 100px 0;
  text-align: center;
  background: #f5f5f5;
  overflow: hidden;
}

.post {
  text-align: left;
  border: 1px solid #999;
  padding: 15px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto 20px;
}

.post .post-thumb {
  margin-bottom: 15px;
  overflow: hidden;
}

.post .post-thumb img {
  width: 100%;
}

.post .post-meta {
  height: 55px;
  width: 55px;
  background: #000;
  float: left;
  text-align: center;
}

.post .post-meta p {
  color: #FFF;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding-bottom: 3px;
}

.post .post-meta p:after {
  position: absolute;
  content: '';
  height: 2px;
  width: 35px;
  left: 10px;
  bottom: 0;
  background: #FFF;
}

.post .post-meta span {
  color: #FFF;
  font-size: 16px;
}

.post .post-title {
  margin-left: 15px;
  color: #000;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.post .post-title h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.post .post-title:hover {
  color: #f45c27;
}

.post .post-title {
  margin-bottom: 0;
}

.post .athor {
  margin-bottom: 20px;
  margin-left: 70px;
  font-weight: 500;
  color: #999;
}

.post .comm {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.post .comm i {
  margin-right: 10px;
}

.post .read-more {
  float: right;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background: transparent;
}

.post .read-more i {
  margin-left: 5px;
}

.post .read-more:hover {
  color: #f45c27;
}

/*=========  Blog Two  ==========*/
.blog-two .post {
  padding: 10px;
}

.blog-two .post-meta {
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  padding: 0;
  height: auto;
  width: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.blog-two .post-meta li {
  display: inline-block;
  margin-right: 10px;
}

.blog-two .post-meta li a {
  display: block;
  color: #353535;
}

.blog-two .post-meta li a i {
  margin-right: 5px;
  color: #000;
}

.blog-two .post-meta li .post-title {
  margin-left: 0;
}

.post-thumb.video-thumb {
  position: relative;
}

.post-thumb.video-thumb:before {
  content: "\f04b";
  font-family: 'FontAwesome';
  font-size: 25px;
  margin-top: 7px;
  margin-left: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: transform .2s, opacity .2s;
  -moz-transition: transform .2s, opacity .2s;
  -webkit-transition: transform .2s, opacity .2s;
}

.post-thumb.video-thumb:after {
  content: '';
  position: absolute;
  margin-top: 6px;
  height: 60px;
  width: 60px;
  border: none;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  top: 50%;
  left: 50%;
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transition: background-color .2s, transform .2s, opacity 1.2s;
  -moz-transition: background-color .2s, transform .2s, opacity .2s;
  -webkit-transition: background-color .2s, transform;
}

.post-thumb.video-thumb .youtube-wrapper .youtube-poster {
  height: 476px;
}

/*=========  Blog Single  ==========*/
.blog_single .blog-post {
  background-color: transparent !important;
}

.blog_single .blog-content {
  padding: 0 !important;
}

.blog_single .blog-content p {
  margin-bottom: 20px;
}

.blog_single .blog-content blockquote {
  background: #f5f5f5;
  padding: 30px;
  border-left: 3px solid #f45c27;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  font-style: italic;
  margin: 30px 0;
}

.post-thumb {
  position: relative;
}

.post-thumb:hover .blog-gallery-prev, .post-thumb:hover .blog-gallery-next {
  opacity: 1;
}

.tag-area {
  margin-bottom: 50px;
}

.tag-area a {
  padding: 0 10px;
  background: #d4d4d4;
  font-size: 10px;
  margin-right: 10px;
}

.blog-gallery-prev, .blog-gallery-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%, 50%);
  height: 35px;
  width: 35px;
  line-height: 35px;
  border: 1px solid #000;
  z-index: 999;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.blog-gallery-prev:hover, .blog-gallery-next:hover {
  background: #f45c27;
  border: transparent;
  color: #FFF;
}

.blog-gallery-next {
  right: 0;
}

.gp-comments {
  margin-bottom: 80px;
  padding: 0;
  margin: 0;
}

.post-comment {
  margin: 30px 0;
}

.comment-content {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.post-comment .avatar {
  margin-right: 22px;
  float: left;
}

.post-comment .avatar img {
  width: 74px;
  height: 74px;
}

.post-comment .post-body {
  overflow: hidden;
}

.post-comment .comment-header {
  line-height: 1;
  font-size: 13px;
  padding-right: 46px;
  margin-top: 3px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.post-comment .author a {
  font-size: 16px;
  font-weight: 700;
  color: #191a1c;
}

.post-comment .comment-header .time-ago a {
  font-size: 12px;
  color: #b1b1b1;
  margin-left: 6px;
}

.post-comment .reply {
  position: absolute;
  top: 15px;
  right: 20px;
}

.post-comment .reply-comment {
  padding-left: 90px;
}

.post-comment .reply-comment .avatar img {
  width: 60px;
  height: 60px;
}

.form-leave-comment {
  width: 100%;
  float: left;
  margin-bottom: 100px;
}

.form-leave-comment h5 {
  margin-left: 5px;
}

.form-leave-comment form {
  margin-top: 30px;
}

.form-leave-comment form .gp-btn {
  border: none;
  transition: all 0.3s ease-in-out;
}

.form-leave-comment form .gp-btn:hover {
  background-color: #f45c27;
}

.form-leave-comment input, .form-leave-comment textarea {
  padding: 14px 18px;
  margin: 0;
  width: 100%;
  max-width: 100%;
  border: 0px;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px;
  color: #555;
  width: 100%;
  margin-bottom: 14px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 4px;
  outline: none;
}

.form-leave-comment textarea {
  height: 150px;
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
#contact {
  padding: 100px 0;
  text-align: center;
}

.contact-form {
  padding: 60px 20px 20px;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.contact-form .contact-title {
  background: #f45c27;
  position: absolute;
  padding: 10px 20px;
  font-size: 20px;
  color: #FFF;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  width: 250px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.contact-form .contact-title i {
  margin-right: 10px;
}

.contact-form .form p {
  margin-bottom: 10px;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px 0;
}

.contact-form .form p i {
  margin-right: 10px;
  float: left;
  display: inline;
  line-height: 25px;
  font-size: 20px;
}

.contact-form .form p input, .contact-form .form p textarea {
  border: none;
  width: 90%;
  display: inline;
  outline: none;
  font-size: 16px;
  background-color: transparent;
}

.contact-form .form p textarea {
  height: 150px;
}

.contact-form .form .gp-btn {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  border: none;
  outline: none;
}

.adress {
  text-align: center;
}

.adress .con-icon {
  height: 70px;
  width: 70px;
  background: #f45c27;
  text-align: center;
  border-radius: 50%;
  line-height: 80px;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.4);
}

.adress .con-icon i {
  color: #FFF;
  font-size: 30px;
}

.adress p {
  font-size: 16px;
}

.gmap3-area {
  height: 400px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

/*=========  Contact Two  ==========*/
#contact.contact-two {
  padding: 100px 0 0;
}

#contact.contact-two .contact-wrapper {
  margin-bottom: 50px;
}

#contact.contact-two .contact-form {
  padding-top: 50px;
}

#contact.contact-two .contact-form .form p {
  padding: 5px 0;
  margin-bottom: 5px;
  background-color: transparent;
}

#contact.contact-two .contact-form .form p textarea {
  height: 120px;
}

#contact.contact-two .contact-form .form .gp-btn {
  margin-top: 15px;
}

#contact.contact-two .adress {
  margin-bottom: 51px;
  padding: 20px 10px;
  background: #f5f5f5;
  text-align: left;
  box-shadow: 0 6px 8px rgba(50, 50, 50, 0.1);
}

#contact.contact-two .adress .con-icon {
  float: left;
  margin-bottom: 0;
  background: #f45c27;
}

#contact.contact-two .adress p {
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: 90px;
}

#map .gmap3-area {
  margin-bottom: 0;
  height: 600px;
}

/*--------------------------------------------------------------
  ##  Experience
  --------------------------------------------------------------*/
#experience {
  padding: 70px 0;
  text-align: center;
}

#experience h2 {
  font-size: 50px;
  color: #f45c27;
  text-transform: uppercase;
  font-weight: 700;
}

#experience p {
  font-size: 25px;
  margin-bottom: 30px;
}

#experience .award {
  float: left;
  padding: 15px;
  height: 150px;
  width: 150px;
}

#experience .award img {
  width: 100%;
}

.award-des {
  text-align: left;
}

.award-des .award-title {
  font-size: 30px;
  margin-bottom: 30px;
}

.award-des p {
  font-size: 18px	!important;
  font-size: 500px;
}

/*=========  Business Exprience  ==========*/
.bs-exprience {
  padding: 70px 0;
  background: #f45c27;
}

.bs-exprience h3 {
  color: #FFF;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 30px;
}

.bs-exprience h3 span {
  font-size: 30px;
  font-weight: 700;
}

.bs-exprience .gp-btn {
  display: inline-block;
}

/*--------------------------------------------------------------
  ##  Work Process
  --------------------------------------------------------------*/
#work-process {
  padding: 100px 0 0;
  text-align: center;
  background: #e6e6e6;
}

#work-process .work {
  padding: 50px;
  text-align: left;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

#work-process .work h3 {
  color: #f45c27;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 700;
}

#work-process .work p {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
}

#work-process .work a {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  background: #252525;
  padding: 10px 10px;
  line-height: 14px;
}

#work-process .work a:hover {
  background: #f45c27;
}

#work-process .work:hover {
  z-index: 2;
  transform: scale(1.1);
}

.tp-caption h1 {
  font-size: 50px;
  z-index: 100;
}

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
#newsletter {
  padding: 100px 0;
  background: #f45c27;
  text-align: center;
  background-size: cover;
}

#newsletter h2 {
  color: #FFF;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 900;
  margin-bottom: 30px;
  letter-spacing: 0.05em;
  font-family: Raleway;
}

#newsletter i {
  margin-bottom: 20px;
  color: #FFF;
  font-size: 35px;
  display: block;
}

#newsletter #form input[type="text"] {
  background-color: #FFF;
  height: 46px;
  line-height: 45px;
  width: 400px;
  padding: 0 20px;
  outline: none;
  color: #000;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: none;
}

#newsletter .gp-btn {
  border: 0;
  outline: none;
  background: #000;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  margin-left: -4px;
  width: 150px !important;
  height: 46px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

#newsletter .gp-btn i {
  margin-right: 10px;
}

#newsletter .gp-btn:hover {
  background: #f45c27 !important;
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  Blog Page
  --------------------------------------------------------------*/
.blog-banner {
  padding: 150px 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-banner-caption h3 {
  font-size: 45px;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 800;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  line-height: 40px;
  margin-bottom: 30px;
}

.blog-banner-caption h3:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #FFF;
}

.blog-banner-caption p {
  color: #FFF;
  font-weight: 700;
  font-size: 18px;
  font-style: italic;
}

/*=========  Blog Style One  ==========*/
.blog {
  padding: 70px 0;
}

.blog .post {
  margin-bottom: 30px;
}

/*=========  Blog style two  ==========*/
.blog-style-two {
  padding: 70px 0;
}

.blog-style-two .blog-post {
  margin-bottom: 50px;
  background: #f9f9f9;
}

.blog-style-two .blog-post .post-thumb {
  overflow: hidden;
}

.blog-style-two .blog-post .post-thumb img {
  width: 100%;
}

.blog-style-two .blog-post .youtube-wrapper .youtube-poster {
  height: 480px;
}

.blog-style-two .blog-content {
  padding: 0 20px 20px;
}

.blog-style-two .post-meta {
  margin: 20px 0;
}

.blog-style-two .post-meta .meta {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #999;
  padding-bottom: 10px;
}

.blog-style-two .post-meta .meta li {
  display: inline-block;
  margin-right: 10px;
}

.blog-style-two .post-meta .meta li a {
  font-weight: 600;
  color: #505050;
}

.blog-style-two .post-meta .meta li a i {
  margin-right: 5px;
  color: #000;
}

.blog-style-two .post-meta .meta li a:hover {
  color: #f45c27;
}

.blog-style-two .blog-title {
  color: #000;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.blog-style-two .blog-title:hover {
  color: #f45c27;
}

.blog-style-two a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.blog-style-two a:hover {
  color: #f45c27;
}

.page-control {
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.page-control li {
  display: inline-block;
  margin: 0 5px;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: #f5f5f5;
  border-radius: 3px;
}

.page-control li:hover, .page-control li.page-active {
  background: #f45c27;
  cursor: pointer;
}

.page-control li:hover a, .page-control li.page-active a {
  color: #FFF;
  display: block;
}

/*--------------------------------------------------------------
  ##  Start
  --------------------------------------------------------------*/
#start {
  padding: 70px 0;
  text-align: center;
  color: #FFF;
}

#start h3 {
  font-size: 28px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
}

#start p {
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

#start .gp-btn {
  background: #6f6f6f;
  color: #FFF;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 14px 20px;
}

#start .gp-btn:hover {
  background: #f45c27;
}

/*--------------------------------------------------------------
  ##  Choose
  --------------------------------------------------------------*/
#choose {
  padding: 100px 0;
}

#choose h3 {
  margin-top: 50px;
}

#choose .list {
  margin: 20px 0 0 100px;
  padding: 0;
  list-style: none;
}

#choose .list li {
  width: 50%;
  float: left;
  line-height: 30px;
  color: #000;
  font-weight: 600;
}

#choose .list li i {
  margin-right: 10px;
}

.tab {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
  font-weight: 300;
  color: #888;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  height: 450px;
}

.tabs {
  display: table;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.tabs li {
  line-height: 38px;
  overflow: hidden;
  padding: 0;
  position: relative;
  display: inline-block;
}

.tabs li a {
  background-color: #eff0f2;
  border-bottom: 1px solid #fff;
  color: #888;
  font-weight: 500;
  display: block;
  letter-spacing: 0;
  outline: none;
  padding: 0 17px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid #f45c27;
}

.tabs li.current a {
  color: #fff;
  background: #f45c27;
}

.tabs_item {
  display: none;
  padding: 20px;
}

.tabs_item h4 {
  font-weight: bold;
  color: #f45c27;
  font-size: 20px;
}

.tabs_item img {
  width: 250px;
  float: left;
  margin-right: 30px;
}

.tabs_item:first-child {
  display: block;
}

.tab_content {
  padding: 10px 0;
}

.panel-group {
  margin-top: 20px;
}

.panel-body p {
  margin-bottom: 0;
}

.panel-default {
  text-align: left;
}

.panel-default > .panel-heading {
  background-color: #f45c27;
  padding: 15px;
  color: #FFF;
  text-decoration: none;
}

.panel-default > .panel-heading a:hover, .panel-default > .panel-heading a:focus {
  text-decoration: none;
}

.panel-heading .accordion-toggle:after {
  font-family: "dt-icons";
  content: "\e041";
  float: right;
  color: #FFF;
  font-size: 20px;
}

.panel-heading .accordion-toggle {
  display: block;
}

.panel-heading .accordion-toggle.collapsed:after {
  content: "\e048";
}

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
/*=========  Search  ==========*/
.widget_dt_search .search-form {
  width: 100%;
}

.widget_dt_search .search-form label {
  position: relative;
  width: 270px;
  margin-bottom: 0;
}

.widget_dt_search .search-form .search-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

.widget_dt_search input[type="search"] {
  width: 100%;
  border: 1px solid #eeeeee;
  outline: none;
  padding: 8px 20px 8px 8px;
  color: #111;
  font-size: 14px;
  background: #f5f5f5;
}

.widget_dt_search .search-submit {
  display: none;
}

/*=========  Recent Post  ==========*/
.widget_recent_entries .resent-post {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.widget_recent_entries .resent-post .post-thumbs {
  float: left;
  border-radius: 5px;
  overflow: hidden;
}

.widget_recent_entries .resent-post .post-content {
  margin-left: 90px;
}

.widget_recent_entries .resent-post .post-content h5 {
  font-size: 13px;
  margin-top: 0;
}

.widget_recent_entries .resent-post .post-content h5 a {
  color: #111111;
  line-height: 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 700;
}

.widget_recent_entries .resent-post .post-content h5 a:hover {
  color: #f45c27;
}

.widget_recent_entries .resent-post .post-content .post-meta {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_recent_entries .resent-post .post-content .post-meta li {
  display: inline;
  padding-right: 10px;
}

.widget_recent_entries .resent-post .post-content .post-meta li a {
  color: #f45c27;
  text-decoration: none;
}

.widget_recent_entries .resent-post .post-content .post-meta li a i {
  padding-right: 10px;
}

/*=========  Categories  ==========*/
.widget {
  margin-bottom: 30px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 3px;
}

.widget .widget-title {
  position: relative;
  font-size: 20px;
  color: #111;
  margin-top: 0;
  margin-bottom: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
}

.widget .widget-title:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  left: 0;
  bottom: 0;
  background: #f45c27;
}

.widget_nav_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_nav_menu ul li {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.widget_nav_menu ul li:last-child {
  border-bottom: none;
}

.widget_nav_menu ul li a {
  color: #6d6c6c;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
}

.widget_nav_menu ul li a i {
  margin-right: 5px;
}

.widget_nav_menu ul li a:hover {
  color: #f45c27;
}

/*=========  Tags  ==========*/
/* Dt Tags */
.dt-tag a {
  color: #fff;
  border: 1px solid #eee;
  text-decoration: none;
  padding: 3px 6px;
  display: inline-block;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  background: #000;
  font-size: 13px;
}

.dt-tag a:hover {
  background: #f45c27;
  color: #FFF;
}

/*--------------------------------------------------------------
  ##  My Exprience
  --------------------------------------------------------------*/
.exprience {
  position: relative;
  padding: 50px 0;
  text-align: center;
}

.exprience::before {
  content: '';
  background: #f5f5f5;
  width: 5px;
  height: 60%;
  position: absolute;
  left: 50%;
  top: 22%;
  transform: translateX(-50%);
}

.exprience .section-title {
  margin-bottom: 50px;
}

.exprience .year {
  position: absolute;
  top: 46px;
}

.exprience .year span {
  font-weight: 700;
  color: #f45c27;
  font-size: 18px;
  text-transform: uppercase;
}

.timeline-item {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
}

.timeline-item .present.year {
  left: 40% !important;
}

.timeline-item:nth-child(even) .timeline-content {
  float: right;
  padding: 20px;
}

.timeline-item:nth-child(even) .timeline-content .date {
  right: auto;
  left: 0;
}

.timeline-item:nth-child(even) .timeline-content::after {
  content: '';
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  left: -15px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #f5f5f5 transparent transparent;
}

.timeline-item:nth-child(even) .year {
  left: 44%;
}

.timeline-item:nth-child(odd) .year {
  right: 44%;
}

.timeline-item::after {
  content: '';
  display: block;
  clear: both;
}

.timeline-content {
  position: relative;
  width: 45%;
  padding: 10px 30px;
  border-radius: 4px;
  background: #f5f5f5;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.timeline-content::after {
  content: '';
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  right: -15px;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #f5f5f5;
}

.timeline-img {
  width: 30px;
  height: 30px;
  background: #f45c27;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -15px;
}

.timeline-content h3 {
  font-size: 20px;
}

.timeline-content h3 span {
  color: #f45c27;
}

.timeline-content p {
  font-size: 12px;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .timeline::before {
    left: 50px;
  }
  .timeline .timeline-img {
    left: 50px;
  }
  .timeline .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 70px;
  }
  .timeline .timeline-item:nth-child(even) .timeline-content {
    float: none;
  }
  .timeline .timeline-item:nth-child(odd) .timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

/*--------------------------------------------------------------
  ##  footer
  --------------------------------------------------------------*/
#footer {
  background: #252525;
  padding: 50px 0 0;
}

#footer .footer-title {
  color: #FFF;
  text-transform: uppercase;
  margin-bottom: 30px;
}

#footer p {
  color: #FFF;
  font-size: 13px;
  letter-spacing: 0.05em;
}

#footer .gp-btn {
  margin-top: 30px;
}

.footer-two {
  padding: 100px 0 !important;
  text-align: center;
}

.footer-two h2 {
  color: #FFF;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}

.footer-two li {
  overflow: hidden;
  height: 60px;
  width: 60px;
}

.footer-two li a i {
  position: relative;
  overflow: hidden;
}

.footer-two li a i:before, .footer-two li a i:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 24px;
  position: absolute;
  height: 60px;
  width: 60px;
  left: -30px;
  line-height: 60px;
  transition: all 0.2s ease-in-out;
}

.footer-two li a i:after {
  transform: translateY(50px);
}

.footer-two li a:hover i:before {
  transform: translateY(-50px);
}

.footer-two li a:hover i:after {
  transform: translateY(0);
}

.footer-two li.facebook a i:before, .footer-two li.facebook a i:after {
  content: "\f09a";
}

.footer-two li.facebook:hover {
  background: #3b5998;
}

.footer-two li.twitter a i:before, .footer-two li.twitter a i:after {
  content: "\f099";
}

.footer-two li.twitter:hover {
  background: #1da1f2;
}

.footer-two li.instagram a i:before, .footer-two li.instagram a i:after {
  content: "\f16d";
}

.footer-two li.instagram:hover {
  background: radial-gradient(ellipse at 70% 70%, #ee583f 8%, #d92d77 42%, #bd3381 58%);
}

.footer-two li.linkedin a i:before, .footer-two li.linkedin a i:after {
  content: "\f0e1";
}

.footer-two li.linkedin:hover {
  background: #008CC9;
}

.footer-two li.dribbble a i:before, .footer-two li.dribbble a i:after {
  content: "\f17d";
}

.footer-two li.dribbble:hover {
  background: #ea4c89;
}

.footer-two li.behance a i:before, .footer-two li.behance a i:after {
  content: "\f1b4";
}

.footer-two li.behance:hover {
  background: linear-gradient(#0096ff, #005dff);
}

.fotter-content .latest-post {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #2f2f2f;
}

.fotter-content .latest-post:last-child {
  border-bottom: none;
}

.fotter-content .latest-post a {
  display: block;
}

.fotter-content .latest-post a img {
  float: left;
}

.fotter-content .latest-post a .content {
  margin-left: 91px;
}

.fotter-content .latest-post a .content h4 {
  color: #FFF;
  font-wight: 700;
  transition: all 0.3s ease-in-out;
}

.fotter-content .latest-post a .content p {
  color: #FFF;
  margin-bottom: 0;
}

.fotter-content .latest-post a:hover .content h4 {
  color: #f45c27;
}

.flicker a {
  display: inline-block;
  margin: 5px;
}

.copyright {
  padding: 22px;
  background: #000;
  text-align: center;
}

.copyright p {
  color: #e3e3e3;
  font-size: 14px !important;
  margin-bottom: 0;
  letter-spacing: 0.05em;
}

.copyright p a {
  color: #f45c27;
  font-weight: 700;
  text-decoration: none;
}

/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@media (max-width: 1440px) {
  .team .gallery-thumbs {
    right: 10%;
  }
  .team .gallery-top .swiper-slide .slider-content {
    max-width: 450px;
  }
  .team .gallery-top .banner-next, .team .gallery-top .banner-prev {
    right: 12.5%;
  }
  /*=========  Skill  ==========*/
  .circle-wrapper {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  /*=========  Header  ==========*/
  .dt-header {
    padding: 5px 0;
  }
  .dt-header .site-logo {
    padding: 5px 0;
  }
  .dt-header .site-logo a img {
    height: 50px;
  }
  nav .sf-menu li a {
    padding: 17px 8px;
  }
  /*=========  Feature  ==========*/
  #feature {
    padding: 50px 0;
  }
  .feature .feature-item {
    padding: 20px 10px;
  }
  .feature .feature-item .feature-content {
    margin-left: 90px;
  }
  .feature .feature-item .feature-content h3 {
    font-size: 20px;
  }
  /*=========  About Us ==========*/
  .about-details p {
    font-size: 14px;
  }
  .about-details .gp-btn {
    margin-top: 10px;
  }
  /* About Two */
  #about {
    padding: 50px 0;
  }
  #about.about-two {
    padding: 50px 0;
  }
  #about.about-two .about-details .our-history .section-title {
    margin-top: 0;
  }
  #about.about-two .about-details .our-history p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  /* About Me */
  .about-me h3 {
    margin-bottom: 15px;
  }
  .about-me h4 {
    font-size: 16px;
  }
  .about-me p {
    font-size: 13px;
    margin: 10px 0;
  }
  /*=========  Timeline ==========*/
  .timeline-item .present.year {
    left: 39% !important;
  }
  .timeline-item:nth-child(odd) .year {
    right: 43%;
  }
  .timeline-item:nth-child(even) .year {
    left: 43%;
  }
  /*=========  Exprience ==========*/
  .bs-exprience h3 {
    font-size: 20px;
  }
  /*=========  Tabs ==========*/
  #choose {
    padding: 50px 0;
  }
  #choose .list {
    margin: 0 0 0 60px;
  }
  .tabs li a {
    padding: 0 14px;
  }
  .tabs_item {
    padding: 10px;
  }
  .tabs_item img {
    margin-right: 15px;
  }
  /*=========  Team  ==========*/
  .team .gallery-thumbs {
    right: 3%;
  }
  .team .gallery-top .swiper-slide .slider-content {
    max-width: 450px;
  }
  .team .gallery-top .banner-next, .team .gallery-top .banner-prev {
    right: 5.5%;
  }
  #team-two .gallery-top .swiper-slide img {
    width: 220px;
  }
  #team-two .gallery-top .swiper-slide .slider-content {
    margin-left: 230px;
  }
  #team-two .gallery-top .swiper-slide .slider-content h4 {
    margin-top: 0;
  }
  #team-two .gallery-top .swiper-slide .slider-content span {
    margin-bottom: 10px;
  }
  #team-two .gallery-top .swiper-slide .slider-content p {
    margin-bottom: 10px;
  }
  #team-two .gallery-top .banner-next, #team-two .gallery-top .banner-prev {
    right: 5%;
  }
  #team-two .gallery-top .banner-next {
    bottom: 15px;
  }
  #team-two .gallery-thumbs {
    right: 2%;
  }
  /* Team Two */
  .team-two {
    padding: 50px 0;
  }
  .member .team-overlay {
    width: 95%;
    height: 96.5%;
  }
  .member .team-overlay .name {
    margin-top: 80px;
  }
  .member .team-overlay .name p {
    font-size: 13px;
  }
  /* Team Three */
  .team-three {
    padding: 50px 0;
  }
  /*=========  Skills  ==========*/
  #skills .skills p br {
    display: none;
  }
  .circle-wrapper {
    width: 100%;
    transform: scale(0.8) translate(-20px);
  }
  .skills-thumb {
    height: 400px;
  }
  /* Skills Two */
  #skills.skills-two {
    padding: 50px 0;
  }
  .client .clients-items .item {
    width: 30%;
    margin: 7px;
  }
  .skills-heading span, .skills-heading p {
    font-size: 14px;
  }
  progress {
    margin-bottom: 0;
  }
  /* Skill Four */
  #skills.skills-four {
    padding-top: 50px;
  }
  /*=========  Portfolio ==========*/
  #portfolio {
    padding: 50px 0;
  }
  /*=========  Countup  ==========*/
  .countup {
    padding: 50px 0;
  }
  /*=========  Follow  ==========*/
  #follow {
    padding: 50px 0;
  }
  /*=========  Service  ==========*/
  #service {
    padding: 50px 0 0;
  }
  #service p {
    margin-bottom: 40px;
  }
  .service-item span {
    height: 70px;
    width: 70px;
    font-size: 30px;
    line-height: 80px;
  }
  .service-item .service-content {
    margin-left: 80px;
  }
  .service-item .service-content h3 {
    font-size: 18px;
  }
  .service-item .service-content p {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 20px;
  }
  .service-item.item-right .service-content {
    margin-right: 80px;
  }
  /*=========  Like  ==========*/
  #like {
    padding: 50px 0;
  }
  #like h2 {
    margin-bottom: 20px;
  }
  #like p {
    font-size: 15px;
  }
  #like p br {
    display: none;
  }
  /*=========  Pricing  ==========*/
  #pricing {
    padding: 50px 0;
  }
  /*=========  Testomonial  ==========*/
  #testimonial {
    padding: 70px 0;
  }
  #testimonial .testi-wrapper {
    width: 90%;
  }
  /* Testimonial */
  #testimonial.testimonial-two .tm-control {
    bottom: 20px;
  }
  /*=========  Brand  ==========*/
  #brand {
    padding: 50px 0;
  }
  /*=========  Blog  ==========*/
  #blog {
    padding: 50px 0;
  }
  /*=========  Newsletter  ==========*/
  #newsletter {
    padding: 50px 0;
  }
  /*=========  Contact  ==========*/
  #contact {
    padding: 50px 0;
  }
  .contact-form .form p input, .contact-form .form p textarea {
    width: 85%;
  }
  /* Contact Two */
  #contact.contact-two {
    padding: 50px 0 0;
  }
  #contact.contact-two .adress p {
    margin-left: 80px;
  }
  /*=========  Map  ==========*/
  #map .gmap3-area {
    height: 400px;
  }
}

@media (max-width: 992px) {
  /*=========  Skills  ==========*/
  .circle-wrapper {
    width: 85%;
  }
}

@media (max-width: 768px) {
  /*=========  Header  ==========*/
  .dt-header {
    display: none;
  }
  .mobile-header {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease-in-out;
  }
  .mobile-header.navbar-small {
    background: #FFF;
    padding: 0;
  }
  .mobile-header.navbar-small .menu-toggle > div {
    background: #000;
  }
  .mobile-header.navbar-small .mobile-logo a.logo-white {
    display: none;
  }
  .mobile-header.navbar-small .mobile-logo a.logo-black {
    display: block;
  }
  .mobile-header.navbar-small .mb-menu-right .mb-menu li a {
    color: #000;
  }
  .burgermenu-left {
    position: absolute;
    left: 20px;
    top: 50%;
    width: 30px;
    transform: translateY(-35%);
  }
  .mobile-logo {
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    width: 50px;
  }
  .mobile-logo a {
    display: block;
  }
  .mobile-logo a img {
    width: 100%;
  }
  .mobile-logo a.logo-white {
    display: block;
  }
  .mobile-logo a.logo-black {
    display: none;
  }
  .mb-menu-right {
    float: right;
  }
  .mb-menu-right .mb-menu {
    margin: 12px 20px;
    padding: 0;
    list-style: none;
  }
  .mb-menu-right .mb-menu li {
    display: inline-block;
    margin-right: 5px;
  }
  .mb-menu-right .mb-menu li a {
    color: #FFF;
  }
  .search-wrapper {
    height: 50px;
  }
  .search-wrapper input {
    margin: 0;
  }
  .search-wrapper .search-btn {
    top: 11px;
    right: 15px;
  }
  .navbar-small .search-wrapper {
    background: #f3f3f3;
    height: 50px;
  }
  .navbar-small .search-wrapper input {
    margin: 0;
  }
  .navbar-small .search-wrapper .search-btn {
    top: 11px;
    right: 15px;
  }
  /*=========  Banner particles  ==========*/
  .banner-particles .banner-content {
    padding: 20px;
  }
  .banner-particles .banner-content h3 {
    font-size: 14px;
  }
  .banner-particles .banner-content h1 {
    font-size: 24px;
  }
  .banner-particles .banner-content p {
    font-size: 12px;
    line-height: 20px;
  }
  .banner-particles .banner-content p br {
    display: none;
  }
  .banner-particles .banner-content .gp-btn {
    padding: 10px 20px;
  }
  /*=========  Feature  ==========*/
  .feature .feature-item {
    max-width: 430px;
    margin: 0 auto 20px;
  }
  /* Feature Two */
  #feature.feature-two .feature-item {
    margin-bottom: 75px;
  }
  /*=========  About ==========*/
  #about .read-more {
    margin-bottom: 20px;
  }
  .about-details .gp-btn {
    margin-bottom: 30px;
  }
  .youtube-wrapper .youtube-poster {
    height: 406px;
  }
  /* About Me */
  .about-me .gp-btn {
    margin-bottom: 30px;
  }
  .ab-thumb {
    max-width: 430px;
    margin: 0 auto 30px;
  }
  /*=========  My Exprience ==========*/
  .bs-exprience {
    text-align: center;
  }
  .bs-exprience h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .bs-exprience h3 span {
    font-size: 22px;
  }
  /*=========  My Exprience ==========*/
  .exprience:before {
    left: 25px;
  }
  .timeline-img {
    left: 0;
  }
  .timeline-content {
    width: 98%;
    float: right;
  }
  .timeline-content:after {
    right: auto;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
  .year {
    display: none;
  }
  /*=========  Team ==========*/
  .team .gallery-top .banner-prev, .team .gallery-top .banner-next {
    transform: rotate(0deg);
    top: 90%;
  }
  .team .gallery-top .banner-prev {
    right: 25%;
  }
  .team .gallery-top .banner-next {
    right: 14%;
    bottom: 35px;
  }
  .team .gallery-top .swiper-slide img {
    width: 220px;
  }
  .team .gallery-top .swiper-slide .slider-content {
    margin-left: 235px;
  }
  .team .gallery-top .swiper-slide .slider-content h4 {
    margin-top: 15px;
  }
  .team .gallery-top .swiper-slide .slider-content span {
    margin-bottom: 15px;
  }
  .team .gallery-top .swiper-slide .slider-content p {
    line-height: 20PX;
    margin-bottom: 12px;
  }
  .team .gallery-top .swiper-slide .slider-content p br {
    display: none;
  }
  .team .gallery-thumbs {
    display: none;
  }
  /* Team Two */
  .team-member-two .member {
    margin-bottom: 20px;
    max-width: 400px;
    margin: 0 auto 20px;
  }
  /*=========  Skill ==========*/
  #skills.skills-four #skills-inner {
    width: 500px;
    margin-bottom: 20px;
  }
  #skills.skills-four .skills-wrapper {
    width: 100%;
  }
  .skills-wrapper {
    max-width: 100%;
  }
  .circle-wrapper {
    transform: scale(0.8) translate(20px);
  }
  /*=========  CountUp ==========*/
  .counter-box {
    margin-bottom: 15px;
  }
  /*=========  Portfolio ==========*/
  #portfolio p br {
    display: none;
  }
  .gp-portfolio-item {
    width: 50%;
  }
  .gp-portfolio-item.width-hulp {
    width: 50%;
  }
  /*=========  Follow ==========*/
  .connected-icons li {
    width: 30%;
  }
  /*=========  Start  ==========*/
  #start p br {
    display: none;
  }
  /*=========  Service ==========*/
  .service-thumb {
    max-width: 300px;
    margin: 0 auto;
  }
  .service-items {
    margin-top: 15px;
  }
  .ser-items-two .service-item {
    max-width: 430px;
    margin: 0 auto 15px;
  }
  /* Service Three */
  .ser-items-three .service-item {
    max-width: 430px;
    margin: 0 auto 20px;
  }
  /*=========  Pricing ==========*/
  .single-pricing {
    margin-bottom: 15px;
  }
  #pricing.pricing-two .single-pricing {
    margin: 0 auto 15px;
  }
  /*=========  Brand ==========*/
  .brand-two .client-thumb {
    max-width: 350px;
    margin: 0 auto 15px;
  }
  /*=========  Testimonial ==========*/
  .testimonial .content {
    max-width: 450px;
  }
  .testimonial .content p {
    font-size: 13px;
  }
  .testimonial .content p br {
    display: none;
  }
  .testimonial .testi-thumb {
    height: 100px;
    width: 100px;
    margin-top: 100px;
    margin-right: 30px;
  }
  .testimonial .testi-thumb img {
    width: 100%;
  }
  .tm-control {
    bottom: -30px;
    right: 32px;
  }
  /* Testimonial Two */
  #testimonial.testimonial-two .testimonial .testi-thumb {
    height: 100px;
    width: 100px;
  }
  #testimonial.testimonial-two .testimonial .content {
    margin: 0 auto;
  }
  /*=========  Blog  ==========*/
  .post {
    max-width: 430px;
  }
}

/*=========  Section footer  ==========*/
@media (max-width: 500px) {
  /*=========  Banner  ==========*/
  .banner-particles .banner-content {
    width: 80%;
  }
  .banner-particles .banner-content h1 {
    font-size: 22px;
  }
  .banner-particles .banner-content h3 {
    font-size: 12px;
  }
  /*=========  Team  ==========*/
  .team .gallery-top .swiper-slide img {
    float: none;
    width: 300px;
    margin: 0 auto;
  }
  .team .gallery-top .swiper-slide .slider-content {
    margin-left: 0;
  }
  /*=========  Skills  ==========*/
  #skills.skills-two .skills-title {
    font-size: 20px;
  }
  #skills.skills-two h4 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  #skills.skills-four #skills-inner {
    width: 100%;
  }
  .client .clients-items {
    margin-bottom: 20px;
  }
  .client .clients-items .item {
    width: 45%;
  }
  #skills .skills p {
    margin-bottom: 0;
  }
  .skill-grid {
    width: 50%;
    margin-bottom: 50px;
  }
  /*=========  Portfolio  ==========*/
  .portfolio-filter {
    margin-bottom: 20px;
    padding-left: 0;
  }
  .portfolio-filter li {
    margin: 0;
  }
  .portfolio-filter li a {
    padding: 8px;
    font-weight: 400;
  }
  .gp-portfolio-item {
    width: 100%;
  }
  .gp-portfolio-item.width-hulp {
    width: 100%;
  }
  /*=========  Follow  ==========*/
  .connected-icons li a span {
    height: 70px;
    width: 70px;
    font-size: 40px;
    line-height: 70px;
    margin-bottom: 15px;
  }
  .connected-icons li a h4 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .connected-icons li a p {
    font-size: 11px !important;
  }
  /*=========  Testimonial  ==========*/
  .testimonial .testi-thumb {
    margin: 0 auto;
    float: none;
  }
  .tm-control {
    width: 80px;
    bottom: -65px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .tm-control .tm-next, .tm-control .tm-prev {
    height: 35px;
    width: 35px;
    line-height: 48px;
  }
  /*=========  Pricing  ==========*/
  #pricing.pricing-two .single-pricing {
    max-width: 350px;
  }
  /*=========  Like  ==========*/
  #like h2 {
    font-size: 20px;
  }
  /*=========  Newsletter  ==========*/
  #newsletter h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  #newsletter .gp-btn {
    width: 100px !important;
    padding: 12px 0;
  }
  #newsletter #form input[type="text"] {
    width: 190px;
  }
  /*=========  Tab  ==========*/
  .tab {
    height: auto;
  }
  .tabs_item img {
    float: none;
    margin-bottom: 10px;
  }
  #choose .list {
    margin: 0;
  }
  #choose .list li {
    font-size: 11px;
  }
  /*=========  Contact  ==========*/
  #contact.contact-two .adress {
    margin-bottom: 25px;
  }
  /*=========  Contact  ==========*/
  .footer-two li {
    height: 50px;
    width: 50px;
  }
  .footer-two li a i:before, .footer-two li a i:after {
    line-height: 50px;
  }
}
