#testimonial {
    padding: 100px 0;
    text-align: center;
    background-size: cover;
    background: #f5f5f5;
    position: relative;
    background-size: cover;
    background-position: center;

    .section-title {
        color: #FFF; }


    p {
        color: #FFF; }

    .testi-wrapper {
        width: 45%;
        margin: 0 auto;
        position: relative; } }


.testimonial-slider {
    margin-top: 50px; }


.testimonial {
    .content {
        float: left;

        h3 {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 1px;
            color: $color_theme;
            // text-transform: uppercase
            margin-top: 0;
            margin-bottom: 10px; }

        span {
            color: #999;
            font-size: 16px;
            font-weight: 700; }

        .star {
            margin: 10px 0 20px;
            padding: 0;
            li {
                display: inline-block;
                margin: 0 5px;
                i {
                    color: $color_theme;
                    font-size: 18px; } } }

        p {
            font-size: 15px;
            letter-spacing: 1px;

            i {
                color: $color_theme;
                font-size: 20px; } } }

    .testi-thumb {
        float: right;
        border-radius: 50%;
        overflow: hidden;
        border: 4px solid $color_theme; } }



.tm-control {
    width: 100px;
    position: absolute;
    right: 25px;
    bottom: -60px;

    .tm-prev, .tm-next {
        height: 40px;
        width: 40px;
        border: 1px solid #FFF;
        line-height: 55px;
        cursor: pointer;


        i {
            font-size: 30px;
            color: #FFF; } }

    .tm-prev {
        float: left; }

    .tm-next {
        float: right; } }


/*=========  Testimonial Two  ==========*/
#testimonial.testimonial-two {

    .testi-wrapper {
        width: 100%;
        position: relative; }


    .testimonial {

        .content {
            float: none; }
        .testi-thumb {
            float: none;
            height: 120px;
            width: 120px;
            margin: 0 auto;
            margin-bottom: 20px;

            img {
                width: 100%; } } }

    .tm-control {
        bottom: 40px;
        left: 50%;
        right: auto;
        transform: translateX(-50%); } }
