@media (max-width: 1440px) {
  .team {
    .gallery-thumbs {
      right: 10%; }

    .gallery-top {
      .swiper-slide {
        .slider-content {
          max-width: 450px; } }

      .banner-next, .banner-prev {
        right: 12.5%; } } }

  /*=========  Skill  ==========*/
  .circle-wrapper {
    width: 90%; } }

@media (max-width: 1024px) {

  /*=========  Header  ==========*/
  .dt-header {
    padding: 5px 0;

    .site-logo {
      padding: 5px 0;

      a {
        img {
          height: 50px; } } } }

  nav {
    .sf-menu {
      li {
        a {
          padding: 17px 8px; } } } }

  /*=========  Feature  ==========*/

  #feature {
    padding: 50px 0; }

  .feature {
    .feature-item {
      padding: 20px 10px;

      .feature-content {
        margin-left: 90px;
        h3 {
          font-size: 20px; } } } }


  /*=========  About Us ==========*/

  .about-details {
    p {
      font-size: 14px; }

    .gp-btn {
      margin-top: 10px; } }

  /* About Two */
  #about {
    padding: 50px 0;
    &.about-two {
      padding: 50px 0;
      .about-details {
        .our-history {
          .section-title {
            margin-top: 0; }

          p {
            margin-bottom: 10px;
            font-size: 14px; } } } } }

  /* About Me */

  .about-me {
    h3 {
      margin-bottom: 15px; }

    h4 {
      font-size: 16px; }
    p {
      font-size: 13px;
      margin: 10px 0; } }


  /*=========  Timeline ==========*/

  .timeline-item {
    .present {
      &.year {
        left: 39% !important; } }

    &:nth-child(odd) {
      .year {
        right: 43%; } }

    &:nth-child(even) {
      .year {
        left: 43%; } } }

  /*=========  Exprience ==========*/

  .bs-exprience {
    h3 {
      font-size: 20px; } }

  /*=========  Tabs ==========*/

  #choose {
    padding: 50px 0;
    .list {
      margin: 0 0 0 60px; } }

  .tabs {
    li {
      a {
        padding: 0 14px; } } }

  .tabs_item {
    padding: 10px;

    img {
      margin-right: 15px; } }



  /*=========  Team  ==========*/

  .team {
    .gallery-thumbs {
      right: 3%; }

    .gallery-top {
      .swiper-slide {
        .slider-content {
          max-width: 450px; } }

      .banner-next, .banner-prev {
        right: 5.5%; } } }

  #team-two {
    .gallery-top {
      .swiper-slide {
        img {
          width: 220px; }

        .slider-content {
          margin-left: 230px;

          h4 {
            margin-top: 0; }

          span {
            margin-bottom: 10px; }

          p {
            margin-bottom: 10px; } } }

      .banner-next, .banner-prev {
        right: 5%; }

      .banner-next {
        bottom: 15px; } }

    .gallery-thumbs {
      right: 2%; } }

  /* Team Two */
  .team-two {
    padding: 50px 0; }

  .member {
    .team-overlay {
      width: 95%;
      height: 96.5%;

      .name {
        margin-top: 80px;

        p {
          font-size: 13px; } } } }

  /* Team Three */
  .team-three {
    padding: 50px 0; }


  /*=========  Skills  ==========*/

  #skills {
    .skills {
      p {
        br {
          display: none; } } } }

  .circle-wrapper {
    width: 100%;
    transform: scale(0.8) translate(-20px); }

  .skills-thumb {
    height: 400px; }

  /* Skills Two */
  #skills {
    &.skills-two {
      padding: 50px 0; } }

  .client {
    .clients-items {
      .item {
        width: 30%;
        margin: 7px; } } }

  .skills-heading {
    span, p {
      font-size: 14px; } }

  progress {
    margin-bottom: 0; }

  /* Skill Four */
  #skills {
    &.skills-four {
      padding-top: 50px; } }

  /*=========  Portfolio ==========*/

  #portfolio {
    padding: 50px 0; }




  /*=========  Countup  ==========*/
  .countup {
    padding: 50px 0; }


  /*=========  Follow  ==========*/
  #follow {
    padding: 50px 0; }

  /*=========  Service  ==========*/

  #service {
    padding: 50px 0 0;

    p {
      margin-bottom: 40px; } }

  .service-item {
    span {
      height: 70px;
      width: 70px;
      font-size: 30px;
      line-height: 80px; }

    .service-content {
      margin-left: 80px;

      h3 {
        font-size: 18px; }

      p {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 20px; } } }

  .service-item {
    &.item-right {
      .service-content {
        margin-right: 80px; } } }

  /*=========  Like  ==========*/

  #like {
    padding: 50px 0;

    h2 {
      margin-bottom: 20px; }

    p {
      font-size: 15px;

      br {
        display: none; } } }

  /*=========  Pricing  ==========*/

  #pricing {
    padding: 50px 0; }


  /*=========  Testomonial  ==========*/

  #testimonial {
    padding: 70px 0;

    .testi-wrapper {
      width: 90%; } }

  /* Testimonial */
  #testimonial {
    &.testimonial-two {
      .tm-control {
        bottom: 20px; } } }


  /*=========  Brand  ==========*/

  #brand {
    padding: 50px 0; }

  /*=========  Blog  ==========*/

  #blog {
    padding: 50px 0; }

  /*=========  Newsletter  ==========*/

  #newsletter {
    padding: 50px 0; }

  /*=========  Contact  ==========*/

  #contact {
    padding: 50px 0; }

  .contact-form {
    .form {
      p {
        input, textarea {
          width: 85%; } } } }

  /* Contact Two */


  #contact {
    &.contact-two {
      padding: 50px 0 0;

      .adress {
        p {
          margin-left: 80px; } } } }

  /*=========  Map  ==========*/

  #map {
    .gmap3-area {
      height: 400px; } } }

@media (max-width: 992px) {
  /*=========  Skills  ==========*/
  .circle-wrapper {
    width: 85%; } }

@media (max-width: 768px) {

  /*=========  Header  ==========*/

  .dt-header {
    display: none; }

  .mobile-header {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    transition: all 0.3s ease-in-out;

    &.navbar-small {
      background: #FFF;
      padding: 0;

      .menu-toggle > div {
        background: #000; }


      .mobile-logo {
        a {
          &.logo-white {
            display: none; }

          &.logo-black {
            display: block; } } }


      .mb-menu-right {
        .mb-menu {
          li {
            a {
              color: #000; } } } } } }

  .burgermenu-left {
    position: absolute;
    left: 20px;
    top: 50%;
    width: 30px;
    transform: translateY(-35%); }

  .mobile-logo {
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    width: 50px;

    a {
      display: block;

      img {
        width: 100%; }

      &.logo-white {
        display: block; }

      &.logo-black {
        display: none; } } }

  .mb-menu-right {
    float: right;

    .mb-menu {
      margin: 12px 20px;
      padding: 0;
      list-style: none;


      li {
        display: inline-block;
        margin-right: 5px;

        a {
          color: #FFF; } } } }

  .search-wrapper {
    height: 50px;

    input {
      margin: 0; }

    .search-btn {
      top: 11px;
      right: 15px; } }

  .navbar-small {
    .search-wrapper {
      background: #f3f3f3;
      height: 50px;

      input {
        margin: 0; }

      .search-btn {
        top: 11px;
        right: 15px; } } }

  /*=========  Banner particles  ==========*/

  .banner-particles {
    .banner-content {
      padding: 20px;

      h3 {
        font-size: 14px; }

      h1 {
        font-size: 24px; }

      p {
        font-size: 12px;
        line-height: 20px;

        br {
          display: none; } }

      .gp-btn {
        padding: 10px 20px; } } }

  /*=========  Feature  ==========*/

  .feature {
    .feature-item {
      max-width: 430px;
      margin: 0 auto 20px; } }

  /* Feature Two */

  #feature.feature-two {
    .feature-item {
      margin-bottom: 75px; } }


  /*=========  About ==========*/
  #about {
    .read-more {
      margin-bottom: 20px; } }

  .about-details {
    .gp-btn {
      margin-bottom: 30px; } }


  .youtube-wrapper {
    .youtube-poster {
      height: 406px; } }

  /* About Me */
  .about-me {
    .gp-btn {
      margin-bottom: 30px; } }

  .ab-thumb {
    max-width: 430px;
    margin: 0 auto 30px; }


  /*=========  My Exprience ==========*/

  .bs-exprience {
    text-align: center;

    h3 {
      font-size: 16px;
      margin-bottom: 10px;

      span {
        font-size: 22px; } } }

  /*=========  My Exprience ==========*/
  .exprience {
    &:before {
      left: 25px; } }

  .timeline-img {
    left: 0; }

  .timeline-content {
    width: 98%;
    float: right;

    &:after {
      right: auto;
      left: -15px;
      border-width: 10px 15px 10px 0;
      border-color: transparent #f5f5f5 transparent transparent; } }
  .year {
    display: none; }


  /*=========  Team ==========*/

  .team {
    .gallery-top {
      .banner-prev, .banner-next {
        transform: rotate(0deg);
        top: 90%; }

      .banner-prev {
        right: 25%; }

      .banner-next {
        right: 14%;
        bottom: 35px; }

      .swiper-slide {

        img {
          width: 220px; }

        .slider-content {
          margin-left: 235px;
          h4 {
            margin-top: 15px; }

          span {
            margin-bottom: 15px; }

          p {
            line-height: 20PX;
            margin-bottom: 12px;

            br {
              display: none; } } } } }

    .gallery-thumbs {
      display: none; } }

  /* Team Two */

  .team-member-two {

    .member {
      margin-bottom: 20px;
      max-width: 400px;
      margin: 0 auto 20px; } }


  /*=========  Skill ==========*/

  #skills {
    &.skills-four {
      #skills-inner {
        width: 500px;
        margin-bottom: 20px; } } }

  #skills {
    &.skills-four {
      .skills-wrapper {
        width: 100%; } } }

  .skills-wrapper {
    max-width: 100%; }

  .circle-wrapper {
    transform: scale(0.8) translate(20px); }

  /*=========  CountUp ==========*/

  .counter-box {
    margin-bottom: 15px; }

  /*=========  Portfolio ==========*/

  #portfolio {
    p {
      br {
        display: none; } } }


  .gp-portfolio-item {
    width: 50%;

    &.width-hulp {
      width: 50%; } }

  /*=========  Follow ==========*/

  .connected-icons {
    li {
      width: 30%; } }

  /*=========  Start  ==========*/
  #start {
    p {
      br {
        display: none; } } }



  /*=========  Service ==========*/

  .service-thumb {
    max-width: 300px;
    margin: 0 auto; }

  .service-items {
    margin-top: 15px; }

  .ser-items-two {
    .service-item {
      max-width: 430px;
      margin: 0 auto 15px; } }

  /* Service Three */
  .ser-items-three {
    .service-item {
      max-width: 430px;
      margin: 0 auto 20px; } }


  /*=========  Pricing ==========*/

  .single-pricing {
    margin-bottom: 15px; }

  #pricing {
    &.pricing-two {
      .single-pricing {
        margin: 0 auto 15px; } } }

  /*=========  Brand ==========*/

  .brand-two {
    .client-thumb {
      max-width: 350px;
      margin: 0 auto 15px; } }

  /*=========  Testimonial ==========*/

  .testimonial {
    .content {
      max-width: 450px;
      p {
        font-size: 13px;

        br {
          display: none; } } }


    .testi-thumb {
      height: 100px;
      width: 100px;
      margin-top: 100px;
      margin-right: 30px;

      img {
        width: 100%; } } }

  .tm-control {
    bottom: -30px;
    right: 32px; }

  /* Testimonial Two */

  #testimonial {
    &.testimonial-two {
      .testimonial {
        .testi-thumb {
          height: 100px;
          width: 100px; }
        .content {
          margin: 0 auto; } } } }

  /*=========  Blog  ==========*/
  .post {
    max-width: 430px; } }

/*=========  Section footer  ==========*/

@media (max-width: 500px) {

  /*=========  Banner  ==========*/
  .banner-particles {
    .banner-content {
      width: 80%;

      h1 {
        font-size: 22px; }

      h3 {
        font-size: 12px; } } }


  /*=========  Team  ==========*/

  .team {
    .gallery-top {
      .swiper-slide {
        img {
          float: none;
          width: 300px;
          margin: 0 auto; }

        .slider-content {
          margin-left: 0; } } } }

  /*=========  Skills  ==========*/

  #skills {

    &.skills-two {
      .skills-title {
        font-size: 20px; }

      h4 {
        font-size: 15px;
        margin-bottom: 15px; } }

    &.skills-four {
      #skills-inner {
        width: 100%; } } }

  .client {
    .clients-items {
      margin-bottom: 20px;

      .item {
        width: 45%; } } }

  #skills {
    .skills {
      p {
        margin-bottom: 0; } } }

  .skill-grid {
    width: 50%;
    margin-bottom: 50px; }


  /*=========  Portfolio  ==========*/
  .portfolio-filter {
    margin-bottom: 20px;
    padding-left: 0;

    li {
      margin: 0;
      a {
        padding: 8px;
        font-weight: 400; } } }

  .gp-portfolio-item {
    width: 100%;

    &.width-hulp {
      width: 100%; } }

  /*=========  Follow  ==========*/
  .connected-icons {
    li {
      a {
        span {
          height: 70px;
          width: 70px;
          font-size: 40px;
          line-height: 70px;
          margin-bottom: 15px; }

        h4 {
          font-size: 16px;
          margin-bottom: 0; }

        p {
          font-size: 11px !important; } } } }

  /*=========  Testimonial  ==========*/
  .testimonial {
    .testi-thumb {
      margin: 0 auto;
      float: none; } }

  .tm-control {
    width: 80px;
    bottom: -65px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);

    .tm-next, .tm-prev {
      height: 35px;
      width: 35px;
      line-height: 48px; } }

  /*=========  Pricing  ==========*/
  #pricing {
    &.pricing-two {
      .single-pricing {
        max-width: 350px; } } }

  /*=========  Like  ==========*/
  #like {
    h2 {
      font-size: 20px; } }

  /*=========  Newsletter  ==========*/
  #newsletter {
    h2 {
      font-size: 24px;
      margin-bottom: 15px; }

    .gp-btn {
      width: 100px !important;
      padding: 12px 0; }

    #form {
      input[type="text"] {
        width: 190px; } } }

  /*=========  Tab  ==========*/
  .tab {
    height: auto; }

  .tabs_item {

    img {
      float: none;
      margin-bottom: 10px; } }

  #choose {

    .list {

      margin: 0;

      li {
        font-size: 11px; } } }

  /*=========  Contact  ==========*/
  #contact {
    &.contact-two {
      .adress {
        margin-bottom: 25px; } } }

  /*=========  Contact  ==========*/
  .footer-two {
    li {
      height: 50px;
      width: 50px;

      a {
        i:before, i:after {

          line-height: 50px; } } } } }

