#blog {
    padding: 100px 0;
    text-align: center;
    background: #f5f5f5;
    overflow: hidden; }

.post {
    text-align: left;
    border: 1px solid #999;
    padding: 15px;
    box-shadow: 0 0 40px 0 rgba(0,0,0,0.1);
    margin: 0 auto 20px;

    .post-thumb {
        margin-bottom: 15px;
        overflow: hidden;

        img {
            width: 100%; } }

    .post-meta {
        height: 55px;
        width: 55px;
        background: #000;
        float: left;
        text-align: center;

        p {
            color: #FFF;
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 600;
            position: relative;
            padding-bottom: 3px;

            &:after {
                position: absolute;
                content: '';
                height: 2px;
                width: 35px;
                left: 10px;
                bottom: 0;
                background: #FFF; } }

        span {
            color: #FFF;
            font-size: 16px; } }

    .post-title {
        margin-left: 15px;
        color: #000;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        h3 {
            font-size: 18px;
            font-weight: 700;
            margin: 0; }

        &:hover {
            color: $color_theme; } }


    .post-title {
        margin-bottom: 0; }

    .athor {
        margin-bottom: 20px;
        margin-left: 70px;
        font-weight: 500;
        color: #999; }

    .comm {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        i {
            margin-right: 10px; } }

    .read-more {
        float: right;
        font-weight: 700;
        color: #000;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        background: transparent;


        i {
            margin-left: 5px; }


        &:hover {
            color: $color_theme; } } }

/*=========  Blog Two  ==========*/
.blog-two {

    .post {
            padding: 10px; }

    .post-meta {
        background-color: transparent;
        border-bottom: 1px solid #ccc;
        padding: 0;
        height: auto;
        width: auto;
        margin-bottom: 20px;
        padding-bottom: 10px;

        li {
            display: inline-block;
            margin-right: 10px;

            a {
                display: block;
                color: #353535;

                i {
                    margin-right: 5px;
                    color: #000; } }


            .post-title {
                margin-left: 0; } } } }



.post-thumb {
    &.video-thumb {
        position: relative;

        &:before {
            content: "\f04b";
            font-family: 'FontAwesome';
            font-size: 25px;
            margin-top: 7px;
            margin-left: 3px;
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            z-index: 2;
            cursor: pointer;
            visibility: visible;
            transform: translate3d(-50%, -50%, 0) scale(1);
            -moz-transform: translate3d(-50%, -50%, 0);
            -webkit-transform: translate3d(-50%, -50%, 0);
            transition: transform .2s, opacity .2s;
            -moz-transition: transform .2s, opacity .2s;
            -webkit-transition: transform .2s, opacity .2s; }


        &:after {
            content: '';
            position: absolute;
            margin-top: 6px;
            height: 60px;
            width: 60px;
            border: none;
            border-radius: 100px;
            background-color: rgba(0,0,0,0.5);
            cursor: pointer;
            top: 50%;
            left: 50%;
            visibility: visible;
            transform: translate3d(-50%, -50%, 0) scale(1);
            -moz-transform: translate3d(-50%, -50%, 0);
            -webkit-transform: translate3d(-50%, -50%, 0);
            transition: background-color .2s, transform .2s, opacity 1.2s;
            -moz-transition: background-color .2s, transform .2s, opacity .2s;
            -webkit-transition: background-color .2s, transform; }


        .youtube-wrapper {
            .youtube-poster {
                height: 476px; } } } }


/*=========  Blog Single  ==========*/
.blog_single {
    .blog-post {
        background-color: transparent !important; }

    .blog-content {
        padding: 0 !important;

        p {
            margin-bottom: 20px; }

        blockquote {
            background: #f5f5f5;
            padding: 30px;
            border-left: 3px solid $color_theme;
            font-size: 15px;
            font-weight: 600;
            color: #000;
            font-style: italic;
            margin: 30px 0; } } }


.post-thumb {
    position: relative;

    &:hover {
        .blog-gallery-prev, .blog-gallery-next {
            opacity: 1; } } }

.tag-area {
    margin-bottom: 50px;
    a {
        padding: 0 10px;
        background: #d4d4d4;
        font-size: 10px;
        margin-right: 10px; } }

.blog-gallery-prev, .blog-gallery-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%,50%);
    height: 35px;
    width: 35px;
    line-height: 35px;
    border: 1px solid #000;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: $color_theme;
        border: transparent;
        color: #FFF; } }



.blog-gallery-next {
    right: 0; }

.gp-comments {
    margin-bottom: 80px;
    padding: 0;
    margin: 0; }

.post-comment {
    margin: 30px 0; }

.comment-content {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    position: relative; }

.post-comment {
    .avatar {
        margin-right: 22px;
        float: left; } }

.post-comment {

    .avatar {
        img {
            width: 74px;
            height: 74px; } } }


.post-comment {
    .post-body {
        overflow: hidden; } }

.post-comment {
    .comment-header {
        line-height: 1;
        font-size: 13px;
        padding-right: 46px;
        margin-top: 3px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc; } }

.post-comment {
    .author {
        a {
            font-size: 16px;
            font-weight: 700;
            color: #191a1c; } } }

.post-comment {
    .comment-header {
        .time-ago {
            a {
                font-size: 12px;
                color: #b1b1b1;
                margin-left: 6px; } } } }

.post-comment {
    .reply {
        position: absolute;
        top: 15px;
        right: 20px; } }

.post-comment {
    .reply-comment {
        padding-left: 90px; } }

.post-comment {
    .reply-comment {
        .avatar {
            img {
                width: 60px;
                height: 60px; } } } }


.form-leave-comment {
    width: 100%;
    float: left;
    margin-bottom: 100px; }

.form-leave-comment {
    h5 {
        margin-left: 5px; } }

.form-leave-comment {
    form {
        margin-top: 30px;

        .gp-btn {
            border: none;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $color_theme; } } } }

.form-leave-comment {
    input, textarea {
        padding: 14px 18px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        border: 0px;
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        color: #555;
        width: 100%;
        margin-bottom: 14px;
        background-color: #f5f5f5;
        border: none;
        border-radius: 4px;
        outline: none; }

    textarea {
        height: 150px; } }

